.notification {
  .MuiPaper-root {
    width: 400px;

    .MuiList-root {
      height: 100%;
      padding: 0;
      min-height: 300px !important;
      max-height: 500px !important;
    }

    .notification-header {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      font-size: $title-small;
    }

    .notification-body-wrapper {
      max-height: 446px;
      padding: 8px 12px;
      overflow: auto;

      .notification-body {
        padding: 8px 0;
        font-size: $body;
        color: $text-main-color;

        .dot {
          margin-top: 5px;
          //display: inline-block;
          min-width: 10px;
          min-height: 10px;
          border-radius: 50%;
          background-color: $primary-color;
        }

        .notification-message {
          word-break: break-all;
        }

        .notification-date {
          color: $text-secondary-color;
        }
      }
    }
  }
}
