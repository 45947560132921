.export-timesheets-root-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  position: relative;

  .export-timesheets-sidebar {
    width: 20%;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;

    .export-timesheets-location-menu {
      .MuiButton-root {
        padding: 6px 14px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        max-width: 500px;
        min-width: 100px;
        height: 45px;
        .button-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    @media (max-width: 1024px) {
      .export-timesheets-location-menu .MuiButton-root {
        height: 40px;
      }
    }

    .export-timesheet-side-card-wrapper {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 0px 6px #00000029;
      position: relative;

      .title-text {
        border-bottom: 1px solid $border-color;
        padding: 4px 12px;
      }

      .Mui-checked {
        color: $text-main-color;
      }

      .Mui-selected {
        border: 1px solid $border-active-color;
        background-color: $main-bg-color;
      }

      .person-details {
        .name {
          font-size: $subTitle;
        }
        .details {
          font-size: $body;
          color: $text-secondary-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
        }
      }
    }
  }

  .export-timesheets-list-wrapper {
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .selection-menu .button.MuiButton-root {
      background-color: white;
      color: black;
    }

    .filter-action-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .no-space-btn {
        gap: unset;

        .MuiButtonBase-root {
          box-shadow: unset;
        }

        button {
          border-radius: unset;
          border: 1px solid $primary-color;
          padding: 6px 12px;
          font-size: $body;

          line-height: inherit;
        }

        button:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        button:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .export-timesheets-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      > div:last-child {
        display: flex;
        gap: 10px;
        width: 100%;
        justify-content: flex-end;
      }

      .week-picker {
        width: 100%;
      }

      .MuiToggleButton-root {
        padding: 6px;
        width: 220px;
      }
      @media (max-width: 1024px) {
        .MuiToggleButton-root {
          padding: 4px;
          width: 150px;
        }
      }
    }
  }
}

.export-timesheet-detail-accordion {
  .MuiPaper-root {
    background-color: white;
  }
  .ezy-divider-line {
    background-color: $main-bg-color;
  }
  .accordion-details {
    font-size: $body;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .summary {
      width: 70%;
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
  }
}
