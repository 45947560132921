.ezy-tooltip {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //margin-left: 5px;

  .ezy-tooltip-icon {
    font-size: 22px;
  }
}

.ezy-icon-tooltip {
  color: $primary-color;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //margin-left: 5px;

  .ezy-tooltip-icon {
    font-size: 22px;
  }
}
