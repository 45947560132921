.MuiTableCell-sizeSmall {
  min-width: 100px;
  width: 15%;
  max-width: 300px;

  &.empty-cell {
    width: 10%;
  }
}

.collapsible-table {
  .MuiTableBody-root {
    tr {
      td {
        padding: 3px 16px;
        color: $text-secondary-color;
      }
    }
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
    .material-icons {
      font-size: 20px;
    }
  }
  .white-row {
    background-color: white !important;
    border-bottom: 1px solid $main-bg-color;
    .d-flex {
      display: flex !important;
      gap: 5px;
    }
    .secondary-action-text {
      color: $primary-color;
      text-decoration: underline;
      &:hover {
        color: $primary-hover-color;
      }
    }
  }

  .gray-row {
    background-color: $main-bg-color !important;
    border-bottom: 1px solid white;
    .d-flex {
      display: flex !important;
      gap: 5px;
    }
    .secondary-action-text {
      color: $primary-color;
      text-decoration: underline;
      &:hover {
        color: $primary-hover-color;
      }
    }
  }
}

.collapsible-row {
  > td {
    color: $text-main-color !important;
  }
  cursor: pointer;
  .collapsible-cell {
    padding: 0 !important;
  }
}

.export-items-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
