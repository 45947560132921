.location-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .location-header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 1024px) {
    .location-header {
      height: 40px;
    }
  }

  .table-wrapper {
    width: 100%;
    height: calc(100% - 80px);
    overflow: hidden;
  }
  @media (max-width: 1024px) {
    .table-wrapper {
      height: calc(100% - 60px);
    }
  }
}
