.add-log-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1024px) {
  .add-log-drawer {
    padding: 20px;
  }
}

.email-summary-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .summary-info {
    background-color: $main-bg-color;
    border-radius: 8px;
    font-size: $body;
    gap: 5px;
    padding: 12px;
  }
}
@media (max-width: 1024px) {
  .email-summary-drawer {
    padding: 20px;
  }
}

.log-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000029;
  position: relative;
  background-color: white;

  &.required-log {
    border: 1px solid #ff6565;
    box-shadow: none;
  }

  .title-text {
    border-bottom: 1px solid $border-color;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ezy-menu {
      display: flex;
    }
  }

  hr {
    margin: 0;
  }

  .input-wrapper {
    border-top: 1px solid $border-color;
    padding: 10px;
    .custom-input-field {
      border: 1px solid $border-color;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &:hover {
        border: 1px solid $border-active-color;
      }
      .inputField.MuiFormControl-root .MuiInputBase-root {
        width: 100%;
        border: none;

        input {
          padding: 0;
        }

        &:hover {
          border: none;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        gap: 5px;
      }
    }
  }

  .inputField.MuiFormControl-root .MuiInputAdornment-positionEnd {
    display: flex;
    gap: 4px;
  }

  .iconButton.MuiButton-root {
    padding: 8px;
    border-radius: 6px;
    .material-icons {
      font-size: $title-small;
    }
  }
}

.entry-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;

  .entryList-date-title {
    padding: 4px 10px;
    width: max-content;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    font-size: $body;
    background-color: white;
  }

  .entry-comment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .inputField.MuiFormControl-root .MuiInputBase-root {
      border-radius: 4px;
      input {
        padding: 6px 8px;
      }
    }

    .iconButton.MuiButton-root {
      padding: 5px;
      border-radius: 4px;
      .material-icons {
        font-size: $title-small;
      }
    }
  }

  .entry {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $main-bg-color;
    border-radius: 8px;
    padding: 2px 12px;
    gap: 5px;
  }
}
