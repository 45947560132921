.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 12px;
  background-color: $main-bg-color;

  img {
    width: 30%;
  }

  .title {
    font-size: $title;
  }

  .sub-title {
    width: 600px;
    font-size: $title-small;
    color: $text-secondary-color;
    padding-bottom: 12px;
    word-break: break-word;
    text-align: center;
  }
}
