.w-full {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-full {
  height: 100%;
}

.primary-large-title {
  color: $primary-color;
  font-size: $xx-large;
}

.primary-color {
  color: $primary-color;
}

.subtitle-text {
  color: $text-main-color;
  font-size: $subTitle;
}

.title-text {
  color: $text-main-color;
  font-size: $title-small;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.secondary-color {
  color: $secondary-color;
}

.error-color {
  color: $error-color !important;
}

.white-color {
  color: #ffffff;
}

.secondary-border {
  border-color: $secondary-color !important;
}

.text-secondary-color {
  color: $text-secondary-color;
}

.text-blue-color {
  color: $dark-blue-color;
}

.white-color {
  color: #ffffff;
}

.word-break {
  word-break: break-word;
}

.secondary-action-text {
  font-size: $body;
  color: $secondary-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-center {
  align-self: center;
}

.flex-one {
  flex: 1;
}

.MuiFormHelperText-root.error-helper {
  color: $error-color;
  margin-top: 0;
}

.endAdornment-icon {
  padding: 10px;
  cursor: pointer;
}

.icon-small {
  font-size: $title-small;
}

.two-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
}

.three-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
}

.d-flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: start;
}

.just-bet {
  justify-content: space-between;
}

.just-right {
  justify-content: right;
}

.flex-end {
  align-items: end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-5 {
  gap: 5px;
}

.p-top-15 {
  padding-top: 15px;
}

.p-top-10 {
  padding-top: 10px !important;
}

.p-10 {
  padding: 10px;
}

.p-x-10 {
  padding: 0 10px;
}

.p-24 {
  padding: 24px;
}

.p-bottom-10 {
  padding-bottom: 10px;
}

.p-x-24 {
  padding: 0 24px;
}

.text-line-through {
  text-decoration: line-through;
}

.search-box {
  max-width: 600px;

  .MuiInputBase-root {
    background-color: white;
  }
}

.overflow-auto {
  overflow: auto;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-pad-button {
  &.button.MuiButton-root {
    padding: 8px 16px;
  }
}
@media (max-width: 1024px) {
  .no-pad-button.button.MuiButton-root {
    padding: 6px 14px;
  }
}

.table-paper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px !important;
  box-shadow: 0 0 2px #00000029 !important;
  border: 1px solid white;
}

.primary-bg {
  width: 100%;
  padding: 10px 20px;
  background-color: $main-bg-color;
  font-size: $subTitle;
  border-radius: 8px;
}

.body-text {
  font-size: $body;
}

.pointer-cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.no-border {
  border: none;
  border-radius: unset;
}

.title-with-primary-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 24px;
  height: 42px;
  background-color: $main-bg-color;
  font-size: $title-small;
  border: none;
  border-radius: unset;
}
@media (max-width: 1024px) {
  .title-with-primary-bg {
    height: 38px;
  }
}

.p-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-24 {
  padding: 24px;
}

.display-none {
  display: none;
}

.position-relative {
  position: relative;
}

.justify-content-end {
  justify-content: flex-end;
}

.avatar-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.tooltip-link {
  font-size: $caption;
  font-weight: 500;
  text-decoration: underline;
  color: #ffffff;

  &:hover {
    color: $border-color;
  }
}

.no-data-found {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loader-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
}

.pay-rate-tooltip {
  display: flex;
  gap: 5px;

  .pay-rate-tooltip-data-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .table-tooltip {
    .ezy-tooltip-icon {
      font-size: 18px !important;
    }
  }
}

.secondary-caption-text {
  color: $text-secondary-color;
  font-size: $caption;
}

.material-icon-small {
  .material-icons {
    font-size: 20px;
  }
}

//informative blocks
.info-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 8px;
  padding: 12px;
  font-size: $subTitle;

  &.success {
    color: $primary-color;
    background: $light-success-color;
  }

  &.error {
    color: $error-color;
    background: $light-decline-color;
  }

  &.info {
    color: $secondary-color;
    background: $light-secondary-color;
  }

  &.body-text {
    font-size: $body;

    .material-icons {
      font-size: 20px;
    }
  }
}

.required {
  color: $error-color;
  font-size: $title-small;
  padding-top: 1px;
}

.MuiTable-root,
.chip,
.event-wrapper {
  .MuiChip-root {
    border-radius: 5px;
    height: 27px;
    border-color: $error-color;
    background-color: $light-decline-color;
    color: $error-color;

    &.Pending,
    &.warning,
    &.Date-Approved,
    &.Void,
    &.open,
    &.Awaiting,
    &.Waiting {
      border-color: $secondary-color;
      background-color: $light-secondary-color;
      color: $secondary-color;
    }

    &.Approved,
    &.Accepted {
      border-color: $primary-color;
      background-color: #f1fff4;
      color: $primary-color;
    }

    &.Discarded,
    &.deleted,
    &.Cancelled,
    &.invited {
      border-color: $border-active-color;
      background-color: #f1f1f1;
      color: $border-active-color;
    }

    &.paid,
    &.accept {
      border-color: $primary-color;
      background-color: #ccf8d8;
      color: $primary-color;
    }
  }
}

.full-relative-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.timesheet-history-data-wrapper,
.schedule-shift-history-data-wrapper {
  max-height: max-content;
  transition: max-height 0.25s;
  margin: 0 24px;
  padding: 24px;
  background-color: $main-bg-color;
  border-radius: 8px;
  font-size: $body;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .data-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .title {
      text-align: left;
      font-size: $subTitle;
      white-space: nowrap;
      padding: 0;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $border-active-color;
      word-break: break-word;

      img {
        width: 200px;
        height: 200px;
        padding-top: 8px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .timesheet-history-data-wrapper,
  .schedule-shift-history-data-wrapper {
    padding: 18px;
    margin: 0 20px;
  }
}

.wrap-timesheet,
.wrap-schedule {
  max-height: 0;
  transition: max-height 0.25s;
  overflow: hidden;
}

.wrap-timesheet {
  width: 100px;
}

.timesheet-history-drawer,
.schedule-shift-history-drawer {
  .MuiTimeline-root {
    width: 100%;
    padding: 0 24px;
    align-items: flex-start;

    .MuiTimelineItem-root {
      width: 100%;

      &::before {
        display: none;
      }

      .MuiTimelineContent-root {
        padding: 12px 0 12px 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .timesheet-history-data-wrapper,
        .schedule-shift-history-data-wrapper {
          margin: 0 0;
          padding: 24px;

          .value {
            align-self: center;
          }
        }
        @media (max-width: 1024px) {
          .timesheet-history-data-wrapper,
          .schedule-shift-history-data-wrapper {
            padding: 18px;
          }
        }
      }
    }

    .timesheet-timeline-info,
    .schedule-timeline-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $subTitle;

      .material-icons {
        background: $main-bg-color;
        border-radius: 4px;
        padding: 5px;
        font-size: 20px;
        color: $text-secondary-color;
        cursor: pointer;
      }
    }
  }
}

.href-link {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    color: $primary-hover-color;
    text-decoration: underline;
  }
}
