.task-area-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 6px #00000029;
  position: relative;

  .title-text {
    border-bottom: 1px solid $border-color;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .task-completion-text {
      color: $primary-color;
      font-size: $subTitle;
    }
    .ezy-menu {
      display: flex;
    }
  }

  .task-name {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .task-title {
      font-size: $subTitle;
    }

    hr {
      margin: 0;
    }
  }

  .task-row-note {
    display: flex;
    align-items: flex-start;
    padding-right: 10px;

    .MuiCheckbox-root {
      padding-top: 0;
    }
    .task-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      .task {
        display: flex;
        justify-content: space-between;

        width: 100%;
      }

      .note-icon {
        padding: 5px;
        border-radius: 4px;

        &.iconButton .material-icons {
          font-size: $body;
        }
      }
    }
    .task-note {
      background-color: #f2f5f8;
      border-radius: 8px;
      padding: 8px 12px;

      width: 100%;
      font-size: $caption;
    }
  }

  .simple-task-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.task-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 6px #00000029;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiCheckbox-root {
    margin: 0;
    padding: 0;
  }

  .action-group {
    display: flex;
    gap: 8px;
    margin-top: 2px;
    max-width: 40%;
    align-items: center;
    .no-delete {
      visibility: hidden;
    }

    .profile-picture {
      width: 25px;
      height: 25px;
      font-size: $caption;
    }
  }
}

.task-for-me-drawer,
.task-for-whole-area-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 1024px) {
  .task-for-me-drawer,
  .task-for-whole-area-drawer {
    padding: 20px;
  }
}
.count-wrapper {
  width: 42px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2px;
  .count {
    position: relative;
    z-index: 100;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 3px;
    background-color: $primary-color;
    color: white;
    font-size: $caption;
    cursor: pointer;
  }
  .count:hover {
    display: none;
    transition: 1s ease-in;
    cursor: pointer;
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 3px;
    background-color: $primary-color;
    color: white;
    font-size: $title-small;
    padding-bottom: 2px;
    cursor: pointer;
  }
}
