.dashboard-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;

  // .tab-container .tab-panel {
  //   padding-bottom: 0;
  // }

  .dashboard-sidebar {
    width: 20%;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0 0 4px #00000030;
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .dashboard-business-info {
      width: 100%;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      background: $main-bg-color;
      border-radius: 0 0 16px 16px;
      justify-content: center;
    }
    @media (max-width: 1024px) {
      .dashboard-business-info {
        .material-icons {
          font-size: 20px;
        }
        padding: 6px 12px;
        font-size: $subTitle;
      }
    }
  }
  @media (max-width: 1024px) {
    .dashboard-sidebar {
      width: 25%;
      padding: 0 20px 20px 20px;
    }
  }
}

.people-profile {
  grid-area: profile;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding-top: 40px;

  .profile-picture {
    width: 130px;
    height: 130px;
    font-size: 40px;
    background-color: #bdbdbd;

    .MuiButtonBase-root {
      width: 100%;
    }
  }

  .profile-picture-wrapper {
    position: relative;
    height: 130px;
    width: 130px;
    font-size: 45px;
    background-color: #bdbdbd;
    border-radius: 100%;
    display: flex;
    flex: 0 0 130px;
    justify-content: center;

    //> img {
    //  height: 100%;
    //}
    .people-profile-badge {
      .MuiBadge-badge {
        top: 116px;
        right: 23px;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        border: 2px solid white;
      }

      &.yellow {
        .MuiBadge-badge {
          background-color: $secondary-color;
        }
      }

      &.green {
        .MuiBadge-badge {
          background-color: $primary-color;
        }
      }

      &.blue {
        .MuiBadge-badge {
          background-color: $blue-color;
        }
      }

      &.red {
        .MuiBadge-badge {
          background-color: $error-color;
        }
      }
    }
  }

  > div {
    width: 100%;
    color: $text-secondary-color;
    font-size: $body;

    //.edit-people-profile-start-shift-button {
    //  width: 100%;
    //}
  }

  .shift-button {
    padding: 6px 11px;
  }

  .shift-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    .MuiChip-root {
      height: 40px;
      font-size: $subTitle;
    }
  }

  .profile-name {
    text-align: center;
    font-size: $title;
    color: $text-main-color;
  }

  .MuiButtonBase-root {
    width: 100%;
  }
}
