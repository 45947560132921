$empty-shift-background-color: #f1f1f1;

.calendar-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .schedule-location-menu {
    .MuiButton-root {
      padding: 6px 14px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      max-width: 500px;
      min-width: 100px;
      width: 200px;

      .button-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 1024px) {
    .schedule-location-menu {
      .MuiButton-root {
        width: 150px;
        height: 40px;
      }
    }
  }

  .week-picker {
    width: unset;

    .button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .low-padding {
    padding: 8px 24px;
  }

  .left-items,
  .right-items {
    display: flex;
    align-items: center;
    gap: 10px;

    .duration-menu {
      .MuiButton-root {
        padding: 6px 14px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        max-width: 250px;
        min-width: 100px;
        height: 45px;
        .button-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    @media (max-width: 1024px) {
      .duration-menu {
        .MuiButton-root {
          height: 40px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .left-items,
    .right-items {
      gap: 6px;
    }
  }

  .left-items {
    flex: 1;
  }

  .no-space-btn {
    gap: unset;

    .MuiButtonBase-root {
      box-shadow: unset;
    }

    button {
      border-radius: unset;
      border: 1px solid $primary-color;
    }

    button:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    button:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.calendar-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 6px #00000029;

  .fc {
    background: white;
    border-radius: 8px;
    //padding: 0 10px 10px 10px;
    height: calc(100% - 40px) !important;

    //title theming

    .fc-resource-timeline-divider {
      width: 0;
    }

    // .fc-highlight::before {
    //   content: '+';
    //   display: flex;
    //   width: 100%;
    //   height: 100%;
    //   align-items: center;
    //   justify-content: center;
    //   font-size: $title;
    //   color: $text-secondary-color;
    //   cursor: pointer;
    //   background-color: $main-bg-color;
    // }

    .fc-scrollgrid-section-header > th {
      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }

    //for header
    th {
      .fc-timeline-slot-frame,
      .fc-datagrid-header {
        // height: 50px !important;
        color: $text-main-color;
        text-align: center;
        font-weight: 400;
        font-size: $subTitle;

        &:hover {
          background-color: $hover-color;
          cursor: pointer;
        }
      }
    }

    .fc-timeline-slot {
      border: 1px solid $border-color;

      &.public-holiday {
        background-color: #feeded;
      }

      .custom-header-text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        .material-icons {
          font-size: 16px;
          color: $text-secondary-color;
        }
      }
    }

    .fc-resource-timeline .fc-resource-group,
    .fc-list-day-cushion {
      background-color: $main-bg-color !important;
      font-weight: 400;
      z-index: 2;
      position: relative;
    }

    .fc-resource-group {
      .fc-datagrid-cell-frame {
        height: 30px !important;
        .fc-datagrid-cell-cushion {
          height: 30px;
          font-weight: 400;
          padding: 4px;
          display: flex;
          align-items: center;
          font-size: $body;
          // justify-content: center;
        }
      }
    }

    .fc-resource {
      .fc-datagrid-cell-cushion {
        font-weight: 400;
        padding: 4px;
        display: flex;
        align-items: center;
        font-size: $body;
      }
    }

    .fc-datagrid-header .fc-datagrid-cell-frame {
      // min-height: 50px !important;
      font-weight: 400 !important;
    }

    //event theming
    .fc-timeline-event {
      padding: 1px 5px 1px 5px;
      // margin-bottom: 5px;
      margin-right: 5px;
      border-radius: 5px;
      border-left-width: 5px;
      border-color: $border-active-color;
      background-color: $empty-shift-background-color;
      color: $text-main-color;
    }

    .fc-timeline-events {
      .fc-timeline-event-harness > a {
        margin: 2px 4px 2px 4px;
      }

      .fc-timeline-event-harness:last-child {
        > a {
          margin-bottom: 25px;
        }
      }
    }

    .event-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // gap: 5px;
      color: $text-main-color;
      cursor: pointer;

      &.two-week-view {
        .event-time {
          font-size: $caption;
        }
      }

      &.four-week-view {
        .event-time {
          font-size: $foot-note;
        }
      }

      .event-time {
        width: 100%;
        font-size: $body;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .coverage-event-title {
        width: 100%;
        font-size: $body;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .data-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: $caption;

        line-height: 12px;

        .event-title {
          word-break: break-word;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .event-note {
        // width: 100%;
        font-size: $caption;
        color: $text-secondary-color;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .leave-data-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        > div:last-child {
          overflow: hidden;
        }

        &.two-line {
          flex-direction: column;
          gap: 0;
          width: 100%;
          align-items: flex-start;
          overflow: hidden;

          div {
            width: 100%;
            padding-top: 0;
          }
        }
      }
      @media (max-width: 1024px) {
        .leave-data-wrapper {
          flex-direction: column;
          gap: 0;
        }
      }

      .event-actions {
        display: flex;

        .material-icons {
          font-size: $body;
        }
      }

      .publish-flag {
        position: absolute;
        top: -1px;
        right: -4px;

        .material-icons {
          font-size: $body;
        }
      }

      .archived-flag {
        position: absolute;
        top: -1px;
        right: 10px;

        .material-icons {
          font-size: $body;
        }
      }

      .MuiChip-root {
        border-radius: 5px;
        height: 16px;
        border-color: $error-color;
        background-color: $light-decline-color;
        color: $error-color;
        font-size: $caption;

        .MuiChip-label {
          padding: 5px;
          line-height: 12px;
        }
      }
    }

    .fc-timeline-event.open-shift {
      border-color: $secondary-color;
      background-color: $light-secondary-color;
    }

    .fc-timeline-event.assigned {
      border-color: $primary-color;
      background-color: $light-success-color;
    }

    .fc-timeline-event.locked {
      border-color: $error-color;
      background-color: $light-decline-color;
    }

    .fc-timeline-event.coverage-area {
      border: 0.2px solid rgb(127, 127, 127, 0.5);
      background-color: #ffffff;
      margin: 4px;

      &.error-color {
        border: 0.2px solid $error-color;
        .coverage-event-title {
          color: $error-color;
        }
      }
    }

    .fc-timeline-event.leave_timeoff {
      border-color: #aa0792 !important;
      background-color: #fff1f9 !important;
    }

    .fc-timeline-event.unavailable_timeoff {
      border-color: #0099ff !important;
      background-color: #e9f6ff !important;
    }

    .fc-timeline-event.blink-me {
      animation: blink 1s step-start 0s 15;
    }

    @keyframes blink {
      50% {
        box-shadow: 0 0 12px #00000052;
      }
    }

    .fc-resource {
      .fc-datagrid-cell-frame {
        .fc-icon,
        .fc-datagrid-expander-placeholder {
          display: none;
        }
      }
    }

    //event theming end

    //list theming start
    .fc-list-event-graphic {
      display: none;
    }

    .fc-list-event > td:first-child {
      padding-left: 22px;
      position: relative;

      &::before {
        position: absolute;
        left: 10px;
        content: '';
        background: inherit;
        width: 5px;
        height: 65%;
        border-radius: 8px;
      }
    }

    .fc-list-event.assigned > td:first-child {
      &::before {
        background-color: $primary-color;
      }
    }

    .fc-list-event.open-shift > td:first-child {
      &::before {
        background-color: $secondary-color;
      }
    }

    .fc-list-event.empty-shift > td:first-child {
      &::before {
        background-color: $border-active-color;
      }
    }

    .fc-list-event.locked > td:first-child {
      &::before {
        background-color: $error-color;
      }
    }

    .resource-wrapper {
      display: flex;
      flex-direction: column;
      font-size: $body;

      .resource-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: $caption;
        color: $text-secondary-color;
      }
    }
  }
  @media (max-width: 1024px) {
    .fc {
      height: calc(100% - 30px) !important;
    }
  }

  //footer
  .sticky-footer {
    // bottom: 10px;
    // left: 0;
    // right: 0;
    // position: absolute;
    // width: max-content;
    background: $main-bg-color;
    // margin: auto;
    text-align: center;
    padding: 10px;
    // z-index: 1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: $caption;

    .color-ball {
      width: 12px;
      height: 12px;
      border: none;
      border-radius: 50%;
    }

    .color-ball.empty {
      background-color: white;
      border: 1px solid $border-color;
    }
    .color-ball.unpublished {
      background-color: $border-active-color;
    }
    .color-ball.published {
      background-color: $primary-color;
    }
    .color-ball.require-confirmation {
      background-color: white;
      border: 1px solid $primary-color;
    }
    .color-ball.open {
      background-color: $secondary-color;
    }
    .color-ball.warning {
      background-color: $error-color;
    }
    .color-ball.leave-approved {
      background-color: #aa0792;
    }
    .color-ball.leave-pending {
      background-color: #aa0792;
    }
    .color-ball.unavilable {
      background-color: $blue-color;
    }
  }
  @media (max-width: 1024px) {
    .sticky-footer {
      font-size: $foot-note;
      gap: 4px;
      padding: 8px;
      .color-ball {
        width: 8px;
        height: 8px;
      }
    }
  }
}

//add edit drawer
.shift-drawer,
.schedule-shift-history-drawer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-size: $title-small;
    padding: 0 24px;
  }

  .MuiInputBase-input {
    padding: 10px 20px;
  }

  .shift-drawer-field-grid,
  .repeat-shift-drawer-field-gird {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr;
    gap: 12px;
    grid-auto-flow: row;
  }

  .repeat-shift-drawer-field-gird {
    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .MuiListItem-root {
      border: 1px solid $border-color;
      border-radius: 8px;

      &.Mui-selected {
        background-color: initial;
        border-color: $primary-color;
      }

      .MuiListItemButton-root {
        padding: 8px 16px;
      }
    }

    .Mui-disabled {
      opacity: 0.8;
    }

    .list-view > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .shift-info-wrapper {
    position: relative;
    margin: 0 24px;
    background: $main-bg-color;
    padding: 12px;
    display: grid;
    border-radius: 8px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 12px;
    grid-auto-flow: row;
    grid-template-areas:
      'user user'
      '. .'
      '. .';
  }
  @media (max-width: 1024px) {
    .shift-info-wrapper {
      margin: 0 20px;
      gap: 10px;
    }
  }

  .shift-user-info {
    grid-area: user;
  }

  .shift-user-info,
  .shift-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 10px;

    .subtitle-text {
      color: $text-main-color;
      font-size: $title-small;
    }

    .label {
      color: $text-secondary-color;
      font-size: $body;
    }
  }
  @media (max-width: 1024px) {
    .shift-info,
    .shift-user-info {
      padding: 8px;
      .subtitle-text {
        font-size: $subTitle;
      }
    }
  }

  .round-icon {
    height: 45px;
    width: 45px;
    background: $main-bg-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-secondary-color;
  }

  .publish-checks-lists {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .MuiListItem-root {
      border: 1px solid $border-color;
      border-radius: 8px;

      .MuiListItemButton-root {
        padding: 8px 16px;
        display: flex;
        align-items: flex-start;
      }

      .MuiListItemIcon-root {
        display: flex;
        align-items: flex-start;
        min-width: unset;

        & > span {
          padding: 5px 10px 5px 0;
        }
      }

      .publish-checks-view > span {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > span:last-child {
          color: $text-secondary-color;
        }
      }
    }
  }

  .schedule-details-wrapper {
    margin: 0 24px;
    padding: 24px;
    background-color: $main-bg-color;
    border-radius: 8px;
    font-size: $body;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;

    .data-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      .title {
        align-self: flex-start;
        font-size: $subTitle;
        white-space: nowrap;
        padding: 0;
      }

      .value {
        color: $border-active-color;
        word-break: break-word;
      }
    }
  }
}
@media (max-width: 1024px) {
  .shift-drawer,
  .schedule-shift-history-drawer {
    padding: 20px 0;
    gap: 10px;

    .shift-drawer-field-grid,
    .repeat-shift-drawer-field-gird {
      padding: 0 20px;
    }

    .MuiListItem-root {
      font-size: $body;
    }
  }
}
.cell-hover-elements-container {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  > div {
    flex: 1;
    margin: 1px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    font-size: $title;
    color: $primary-color;
    cursor: pointer;
    line-height: 1;
  }
}

.area-check-list {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .MuiListItem-root {
    border: 1px solid $border-color;
    border-radius: 8px;

    &.Mui-selected {
      border-color: $primary-color;
    }

    .MuiButtonBase-root {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
    }

    .MuiListItemIcon-root {
      min-width: unset;

      & > span {
        padding: 5px;
      }

      .material-icons {
        font-size: 20px;
      }
    }
  }
}

//.calendar-wrapper .fc tr > th {
//  border-top: 0 !important;
//  border-bottom: 0 !important;
//}

//.calendar-wrapper .fc .fc-cell-shaded, .fc-theme-standard td, .fc-theme-standard th {
//  border-top: 0 !important;
//  border-bottom: 0 !important;
//}

.save-template-fields-grid {
  display: grid;
  padding: 0 24px;
  grid-template-columns: 1fr;
  gap: 12px;
  grid-auto-flow: row;

  .search-box {
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .save-template-fields-grid {
    padding: 0 20px;
  }
}

.load-template-lists {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .MuiListItem-root {
    border: 1px solid $border-color;
    border-radius: 8px;

    .MuiListItemButton-root {
      padding: 8px 16px;
      display: flex;
      align-items: flex-start;
    }

    .MuiListItemIcon-root {
      display: flex;
      align-items: center;
      min-width: unset;
    }

    .template-sub-details {
      .material-icons {
        //padding: 5px 10px;
        font-size: 20px;
      }
    }

    .template-details > .MuiListItemText-primary {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

//error modal
.template-error-modal {
  .MuiDialog-paper {
    width: 680px;
    height: auto;

    .MuiTableContainer-root .MuiTableBody-root tr > td {
      white-space: unset;
    }
  }
}

//swap details

.swap-details-row {
  padding: 0 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  align-items: center;

  .swap-details-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid $border-color;
    border-radius: 8px;
    font-size: $body;

    .card-header {
      padding: 8px 12px;
      background-color: $main-bg-color;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .card-body {
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .material-icons {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .swap-details-row {
    padding: 0 20px;
  }
}

.schedule-coverage-dialog {
  &.dialog.common-dialog-with-body .MuiDialog-paper .dialog-body {
    padding-bottom: 0;
  }
  .table-paper {
    max-height: 345px;
  }
  .MuiTable-root td,
  .MuiTable-root th {
    border: 0.5px solid rgba(224, 224, 224, 1);
    background-color: #fff;

    .inputField.MuiFormControl-root .MuiInputBase-root {
      border: unset;
      input {
        padding: 0;
      }
    }
  }
}

.insight-stats-panel {
  font-size: $body;
  .insight-stats-panel-summary {
    padding: 8px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: relative;

    .summary-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      .iconButton.MuiButton-root {
        padding: 5px;
        border-radius: 4px;
        .material-icons {
          font-size: $subTitle;
        }
      }
    }

    .summary {
      display: flex;
      flex-direction: column;
      gap: 5px;
      background-color: $main-bg-color;
      padding: 8px;
      border-radius: 8px;

      &.weekly-summary {
        background-color: #fff;
        padding: 0;
        border-radius: 0;

        .edit-graph-button {
          padding: 2px;
          width: 100%;
        }
      }

      > div {
        display: flex;
        gap: 3px;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .empty-span {
          flex: 1;
          height: 12px;
          border-bottom: 0.2px solid rgba(127, 127, 127, 0.5);
        }
      }
    }
  }
  .stats-panel-details {
    padding: 45px 8px 8px 8px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    position: relative;

    .summary {
      display: flex;
      flex-direction: column;
      gap: 5px;
      background-color: $main-bg-color;
      padding: 8px;
      border-radius: 8px;
    }
  }

  .stats-panel-graph-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .graph-summary {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      padding: 8px;
      gap: 8px;

      > div {
        width: 100%;
        background-color: $main-bg-color;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.description-color {
  height: 10px;
  width: 10px;
  border-radius: 3px;
  border: 1px solid #fff;

  &.required-staff {
    background-color: #1f77bc;
  }

  &.filled-shift {
    background-color: #d4f0fb;
  }

  &.all-shift {
    background-color: #eaeced;
  }

  &.wages {
    background-color: #079e88;
  }

  &.sales {
    background-color: #bc5090;
  }

  &.wages-dashed {
    border-left: 3px solid #079e88;
    border-right: 3px solid #079e88;
    background-color: #fff;
  }

  &.sales-dashed,
  &.sales-dotted {
    border-left: 3px solid #bc5090;
    border-right: 3px solid #bc5090;
    background-color: #fff;
  }
}

.edit-insight-graph-menu {
  max-width: 500px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .label {
    background-color: $main-bg-color;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sub-list-label {
    padding: 0 10px;
    font-size: $body;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label-director {
      width: 15px;
      height: 15px;
      border-radius: 50%;

      &.required-staff {
        background-color: #1f77bc;
      }

      &.filled-shift {
        background-color: #d4f0fb;
      }

      &.all-shift {
        background-color: #eaeced;
      }

      &.wages {
        background-color: #079e88;
      }

      &.sales {
        background-color: #bc5090;
      }

      &.sales-dashed {
        background-color: #fff;
        border: 4px dashed #bc5090;
      }

      &.sales-dotted {
        background-color: #fff;
        border: 3px dotted #bc5090;
      }
    }
  }

  .MuiCheckbox-root {
    padding: 0;
  }
}

#chartjs-tooltip {
  background-color: $primary-color;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  width: 165px;
  z-index: 99;

  .caption-text {
    font-size: $caption;
  }
}
