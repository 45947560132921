.schedule-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .sticky-buttons {
    padding: 5px 10px;
    //background-color: white;
    width: max-content;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    bottom: 5px;
    right: 5px;
    z-index: 2;
    //box-shadow: 0 0 6px #00000029;

    .no-space-btn {
      gap: unset;

      .MuiButtonBase-root {
        box-shadow: unset;
      }

      button {
        border-radius: unset;
        border: 1px solid $primary-color;
      }

      button:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      button:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.schedule-list-wrapper {
  display: flex;
  gap: 12px;
  height: 100%;
  flex-direction: column;

  .schedule-list-table-wrapper {
    width: 100%;
  }
}

.empty-sidebar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: 0 0 6px #00000029;
  border-radius: 8px;
}
