.r-form-wrapper {
  height: calc(100% - 75px);
  padding: 20px 100px;

  .MuiPaper-root {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 15px;

    .register-form-wrapper {
      width: 100%;
      padding: 15px 0;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .form-title {
        width: max-content;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        background: $main-bg-color;
        border-radius: 0 21px 21px 0;
      }

      .left-wrapper {
        width: 100%;
        padding: 0 15px 0 30px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }

    .right-wrapper {
      padding: 15px 15px 15px 0;
    }
  }
}
@media (max-width: 1024px) {
  .r-form-wrapper {
    padding: 20px;
  }
}
