.logbook-root-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  position: relative;

  .logbook-sidebar {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    height: calc(100% - 110px);

    .date-picker .MuiInputBase-root {
      background-color: white;
    }

    .logbook-date-picker {
      .MuiFormControl-root input {
        min-width: unset;
        padding: 12px;
      }
    }

    .logbook-filter-container {
      background-color: #ffffff;
      box-shadow: 0 0 6px #00000029;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .filters {
        cursor: pointer;
        padding: 12px 8px;
        display: flex;
        gap: 16px;
      }
      @media (max-width: 1024px) {
        .filters {
          padding: 8px 6px;
          gap: 8px;
        }
      }

      .selected-filter {
        background-color: $primary-color;
        color: #ffffff;
      }
    }

    .filter-fields-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      overflow: auto;
      height: calc(100% - 100px);
      .MuiAutocomplete-input {
        &.input {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          display: block;
          text-overflow: ellipsis;
        }
      }
    }

    .logbook-side-card-wrapper {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 0px 6px #00000029;
      position: relative;
      height: calc(100% - 182px);
      overflow: hidden;

      .title-text {
        border-bottom: 1px solid $border-color;
        padding: 10px;
      }

      .MuiList-root {
        height: calc(100% - 50px);
        overflow: auto;

        .MuiTypography-root {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .logbook-sidebar {
      width: 25%;
    }
  }

  .logbook-list-wrapper {
    width: 80%;
    padding: 0 8%;
    height: 100%;
    left: calc(20% + 20px);
    position: absolute;
    flex: 1 1;
    overflow: auto;

    .logs-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: max-content;
      grid-gap: 15px;
      padding: 5px;
      margin-top: 20px;
      // overflow: auto;
      height: calc(100% - 66px);
      position: relative;

      .flex-div {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .no-data-found-container {
        margin-top: 0;
      }
    }
  }

  .logs-search-list-wrapper {
    width: 80%;
    padding: 0 8%;
    height: 100%;
    left: calc(20% + 20px);
    position: absolute;
    flex: 1 1;
    overflow: auto;

    > div {
      position: relative;
      background-color: white;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 5px;
    }
  }

  .logbook-list-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $main-bg-color;
  }
}
