.tab-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .tab-panel {
    overflow: auto;
    padding: 24px 0;
    background-color: white;
    height: 100%;
    // box-shadow: 0 0 6px #00000029;
    box-shadow: 0 6px 6px rgb(0 0 0 / 6%);
    border-radius: 12px;
  }

  .tabs {
    &.MuiTabs-root {
      background-color: $main-bg-color;
      padding: 0 40px;
      width: max-content;
      overflow: visible;
      .MuiTabs-scroller {
        overflow: visible !important;
      }

      .MuiTabs-flexContainer {
        padding: 0 5px;
        gap: 14px;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  .tab {
    &.MuiTab-root {
      color: #000000;
      opacity: 0.6;
      font-size: $title-small;
      gap: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: fit-content;
      height: 56px;

      &.Mui-selected {
        opacity: 1;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        margin-top: 6px; // for shadow
        background-color: white;
        //box-shadow: 0 0 6px #00000029;
        position: relative;
        z-index: 3;
        overflow: visible;
        > div {
          overflow: visible;
          position: absolute;
          display: flex;
          &:after,
          &:before {
            content: '';
            background-color: #ffffff;
            width: 15px;
            height: 24px;
            display: block;
            position: relative;
            bottom: -23px;
          }
        }

        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 27px;
          height: 27px;
          bottom: 0;
          z-index: 1;
          background-color: $main-bg-color;
          top: 23px;
          border-radius: 50%;
        }
        &:after {
          left: -27px;
        }
        &:before {
          right: -27px;
        }
      }
    }
  }

  .action-wrapper {
    position: absolute;
    right: 0;
    top: -5px;
    display: flex;
    gap: 15px;
    width: max-content;
    margin: 0;
  }

  //defaultTabs
  .default-tabs {
    &.MuiTabs-root {
      background-color: $main-bg-color;
    }

    .MuiButtonBase-root {
      font-size: $title-small;
    }

    .MuiButtonBase-root.Mui-selected {
      background-color: $primary-color;
      color: white;
      transition: background-color 0.2s ease;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    .default-tabs {
      &.MuiTabs-root {
        min-height: 40px;
      }

      .MuiButtonBase-root {
        min-height: 40px;
        font-size: $subTitle;
        padding: 0;
      }
    }
  }

  .default-tabs-view {
    padding: 12px;
    width: 100%;
    height: calc(100% - 24px);
  }
  .tab-container {
    .MuiTabPanel-root {
      z-index: 20;
    }
  }
}
