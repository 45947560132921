.auto-complete {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .MuiInputLabel-root {
    position: relative;
    font-size: $subTitle;
    color: $text-main-color;
    transform: none;
    display: flex;
    gap: 5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 10px 20px;
      border: 1px solid $border-color;
      border-radius: 8px;
      font-size: $body;
      background-color: white;

      input {
        padding: 0;
        cursor: text;

        &::placeholder {
          font-size: $body;
          color: $placeholder-color;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border: 1px solid $border-active-color;
      }
    }
    @media (max-width: 1024px) {
      .MuiInputBase-root {
        padding: 8px 14px;
      }
    }

    .MuiAutocomplete-input {
      padding: 0;
      min-width: unset;
    }

    .field-error {
      .MuiInputBase-root {
        border: 1px solid $error-color;
      }
    }

    .MuiInputBase-root.Mui-disabled {
      border: 1px solid $border-color;
      box-shadow: none;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.38);
    }

    .MuiAutocomplete-inputRoot {
      .MuiAutocomplete-input {
        min-width: unset;
      }
    }
  }
}

.auto-complete.auto-complete-tags {
  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 1px 20px 1px 0;

      .MuiChip-root {
        border-radius: 5px;
      }
    }
  }
}

.MuiAutocomplete-popper {
  overflow: hidden;
  word-break: break-all;
}
