//css for side-bar
.insight-side-menu {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;

  .area-check-list {
    height: 100%;
    overflow: auto;
  }

  .MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0px 0px 6px #00000029;
    overflow: hidden;
    height: 100%;
  }

  .insight-list-location-menu {
    .MuiButton-root {
      padding: 6px 14px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      max-width: 500px;
      min-width: 100px;
      height: 45px;
      .button-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 1024px) {
    .insight-list-location-menu .MuiButton-root {
      height: 40px;
    }
  }
}

//css for budget
.add-budget-drawer,
.budget-settings-drawer,
.add-metric-drawer,
.export-metric-drawer,
.run-labor-modal-drawer,
.add-rule-drawer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;

  .toggleButtonGroupLabel {
    font-size: $title-small;
  }

  .MuiToggleButton-root {
    text-align: left;
    padding: 4px 24px;

    .subtitle {
      color: $text-secondary-color;
    }

    &.Mui-selected .subtitle {
      color: #ffffff;
    }
  }

  .budget-details-accordion {
    .MuiAccordionSummary-content,
    .budget-entry-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 16px;
      text-align: center;
    }
    @media (max-width: 1024px) {
      .MuiAccordionSummary-content,
      .budget-entry-wrapper {
        gap: 10px;
      }
    }

    .inputField.MuiFormControl-root .MuiInputBase-root input {
      // width: 30px;
      padding: 5px 10px;
      font-size: $body;
    }
  }

  .buffer-wrapper {
    .text-overflow-ellipsis {
      overflow: unset;
    }
  }

  .linear-rule-wrapper {
    gap: 2px;

    .primary-bg {
      border-radius: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .inputField {
        width: 110px;
      }

      .flex-center {
        > span {
          word-break: break-all;
        }
      }

      .d-flex {
        flex-wrap: wrap;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
        .inputField {
          flex: 1;
          min-width: 130px;
        }
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
        .inputField {
          flex: 1;
          min-width: 130px;
        }
      }
    }
  }

  .range-rule-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .inputField {
      width: 100px;
    }

    .text-wrapper {
      width: 60%;
      max-width: 220px;
      min-width: 150px;
      background-color: $border-color;
      padding: 8px 20px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1024px) {
  .add-budget-drawer,
  .budget-settings-drawer,
  .add-metric-drawer,
  .add-rule-drawer {
    padding: 20px;
  }
}
// css for summary
.insight-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;

  .insight-summary-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    > div {
      display: flex;
      gap: 10px;
    }

    .MuiToggleButton-root {
      padding: 8px;
    }
    @media (max-width: 1024px) {
      .MuiToggleButton-root {
        padding: 6px;
      }
    }
  }

  .table-paper {
    height: calc(100% - 60px);

    .MuiTable-root {
      height: 100%;

      tr {
        background-color: #ffffff;

        &:hover {
          background-color: $main-bg-color;
        }
        td {
          height: 60px;
        }
      }

      .last-row {
        td {
          border-bottom: 2px solid #e0e0e0;
        }
      }

      .d-flex-column {
        .error-color,
        .primary-color {
          font-size: $caption;
        }

        > span {
          height: 20px;
        }
      }
    }
  }
}

.metrics-menu {
  .MuiPaper-root,
  .MuiList-root {
    width: max-content !important;
  }
  .MuiMenuItem-root {
    width: 100%;
    .manu-list-value {
      width: 100%;
    }
  }
}

// budget-list
.budget-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;

  .budget-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > div {
      display: flex;
      gap: 10px;
    }

    @media (max-width: 1024px) {
      .budget-setting-button {
        padding: 8px;
        height: 40px;
      }
    }
  }

  .variance-hover-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
}

//metric
.metric-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;

  .metric-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    > div {
      display: flex;
      gap: 10px;
    }
    .button-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.metric-settings-drawer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0;

  .secondary-caption-text {
    text-wrap: wrap;
  }

  .chart-color {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 4px;
    padding: 3px;

    > div {
      width: 100%;
      height: 100%;
      background-color: black;
      border-radius: 3px;
    }
  }
}
.color-palette-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 105px;
  .color-palette {
    display: grid;
    max-width: 400px;
    height: max-content;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;

    .color {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .material-icons {
        font-weight: 800;
        color: white;
      }
    }
  }
  .button.MuiButton-root {
    padding: 3px;
    width: 80px;
    align-self: flex-end;
  }
}

.insight-duration-menu {
  .MuiButton-root {
    max-width: 200px;
    min-width: 100px;
    height: 45px;
    padding: 6px 14px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .button-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
@media (max-width: 1024px) {
  .insight-duration-menu,
  .insight-area-menu {
    .MuiButton-root {
      height: 40px;
    }
  }
}

.edit-metric-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .edit-metric-header {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    height: 45px;
  }

  .edit-metric-component-wrapper {
    flex: 1;
  }
}

//staff module
.labor-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;

  .labor-header {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .labor-list {
    position: relative;
    height: 100%;
    overflow: auto;
    margin: 0 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .list-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .rule-wrapper {
        background-color: #ffffff;
        padding: 20px 12px;
        border-radius: 8px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        color: $text-secondary-color;
        cursor: pointer;

        > div {
          display: flex;
          flex-direction: column;
          gap: 5px;

          > div {
            display: flex;
            gap: 5px;
            align-items: center;
          }
        }

        .text-wrapper {
          background-color: $main-bg-color;
          padding: 8px 20px;
          border-radius: 8px;
          word-break: break-all;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .labor-list {
      margin: 0;
    }
  }
}

.labor-modal-success-dialog {
  .primary-bg {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      max-width: 400px;
      > div:first-child {
        max-width: 360px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &.dialog.common-dialog-with-body .MuiDialog-paper .dialog-body .MuiTableHead-root th,
  td {
    background-color: $main-bg-color;
    border: transparent;
    padding: 5px !important;
  }
}

//staff module
.staff-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;
  position: relative;

  .staff-header {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .staff-area-select {
      width: 170px;
    }

    .MuiInputBase-root {
      height: 45px;
      overflow: hidden;

      &.Mui-focused {
        overflow: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }

    @media (max-width: 1350px) {
      .labor-model-button {
        padding: 8px 15px;
      }
    }
    @media (max-width: 1024px) {
      .week-picker {
        width: 320px;
      }
    }
  }

  .staff-list {
    flex: 1;
    position: relative;
    overflow: auto;
  }
}

.staff-settings-drawer {
  padding: 24px 0;

  .staff-setting-fields {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .toggleButtonGroupLabel {
      font-size: $title-small;
    }

    .MuiToggleButton-root {
      text-align: left;
      padding: 4px 24px;

      .subtitle {
        color: $text-secondary-color;
      }

      &.Mui-selected .subtitle {
        color: #ffffff;
      }
    }
  }
}

.edit-staff-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .edit-staff-header {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    height: 45px;

    > div {
      display: flex;
      gap: 15px;
    }
  }

  .edit-staff-component-wrapper {
    flex: 1;
  }
}

.labor-model-copy-dialog {
  .dialog-body {
    padding-bottom: 0 !important;
  }
  .MuiList-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 12px;
    padding: 0 !important;
  }
}

.summary-location-menu {
  padding: 10px;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  min-width: 250px;

  .location-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 220px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .MuiCheckbox-root {
    padding: 0;
  }

  .location-label {
    display: flex;
    align-items: center;
    gap: 5px;

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
    }
  }

  .area-label {
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }
}
