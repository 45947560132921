.people-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .people-header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    > div:first-child {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
  }
  @media (max-width: 1024px) {
    .people-header {
      height: 40px;
    }
  }

  .table-wrapper {
    width: 100%;
    height: calc(100% - 80px);
    overflow: hidden;
  }
  @media (max-width: 1024px) {
    .table-wrapper {
      height: calc(100% - 60px);
    }
  }
}

.bulk-action-modal {
  .MuiDialog-paper {
    height: auto;
  }

  .invite-user-field {
    color: $border-active-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    position: relative;

    .loader-container {
      justify-content: flex-end;
      position: unset;
      > span {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
