.business-settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.leave-settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  .title-with-primary-bg {
    padding: 5px 24px;
  }
  .leaveRulesTableHeight {
    height: calc(100% - 160px);
  }
}
