.ao-business-root-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ao-business-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .ao-business-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 5px 24px;
    background-color: $main-bg-color;
    font-size: $title-small;
  }
}

.invoice-root-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  .invoice-table-wrapper {
    height: calc(100% - 0px);
  }
}

//Plans

.plan-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .plan-card {
    min-width: 200px;
    max-width: 350px;
    height: fit-content;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 12px;
    gap: 24px;

    .plan-card-header {
      text-align: center;
      width: 80%;
      padding: 12px;
      font-size: $title;
      background: $main-bg-color;
      border-radius: 0 0 12px 12px;
    }

    .plan-card-sub-header {
      text-align: center;
      font-size: $caption;
      color: $text-secondary-color;
    }

    .outlined-primary {
      &.MuiButton-outlined {
        background-color: $light-success-color;
      }
    }

    .plan-card-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
    }
  }

  .plan-card.selected {
    border: 2px solid $primary-color;
  }
}

//view billing
.view-bill-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  overflow: auto;
  gap: 24px;

  .view-bill-field-wrapper {
    width: 100%;
    padding: 12px;
    border: 1px solid $border-color;
    border-radius: 12px;
  }
}

//edit billing
.bill-details-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  .billing-details-container {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px 24px;
    grid-auto-flow: row;
    grid-template-areas:
      'name name .'
      '. . .'
      '. . .'
      '. . .';
  }

  .billing-name {
    grid-area: name;
  }

  .card-details-container {
    width: 100%;
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    gap: 24px;
  }

  .current-card {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $border-color;
    background: $main-bg-color;
    border-radius: 12px;
  }
}

//plan-summary

.plan-and-summary-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
}

.plan-summary-wrapper {
  position: relative;
  min-width: 350px;
  max-width: 450px;
  margin-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 12px;

  .summary-info-wrapper {
    width: 100%;
    height: 100%;
    padding: 12px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  .toggleButtonGroupLabel {
    color: $text-secondary-color;
    font-size: $subTitle;
  }

  .MuiToggleButton-root {
    padding: 5px;
    width: 100%;
  }

  .plan-card-header {
    text-align: center;
    width: 75%;
    padding: 12px;
    font-size: $title;
    background: $main-bg-color;
    border-radius: 0px 0px 24px 24px;
  }

  .amount-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $border-color;
    padding: 12px;
  }

  .value {
    color: $text-secondary-color;
  }
}

.addon-user-drawer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-size: $title-small;
    padding: 0 24px;
  }

  .MuiInputBase-input {
    padding: 10px 20px;
  }

  .field-grid {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    gap: 12px;
    justify-content: space-between;
  }

  .amount-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $border-color;
    padding: 12px;
  }

  .value {
    color: $text-secondary-color;
  }
}

.addon-confirm-dialog {
  .MuiDialog-paper {
    width: 680px;
    height: auto;
  }
}

.view-invoice-drawer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiInputBase-input {
    padding: 10px 20px;
  }

  .view-invoide-details-grid {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr;
    gap: 12px;
    grid-auto-flow: row;

    .view-invoice-note {
      background-color: $main-bg-color;
      border-radius: 8px;
      padding: 16px;
      font-size: $body;
    }

    .view-invoice-field {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 5px;
      line-height: 24px;

      .name-date-field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1px;
        color: $border-active-color;
        flex: 1;
      }

      > span:first-child {
        font-size: $subTitle;
        color: $text-main-color;
      }
    }
    .person-list {
      .MuiList-root {
        padding: 0;
      }
      .MuiListItem-root {
        padding: 6px 12px;
      }
      .MuiTypography-root {
        font-size: $body;
      }
    }
  }
}
@media (max-width: 1024px) {
  .view-invoice-drawer {
    padding: 20px 0;

    .view-invoide-details-grid {
      padding: 0 20px;
    }
  }
}
