.global-profile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  min-width: 200px;
  width: min-content;
  max-width: 260px;

  .profile-settings {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    > div {
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 8px;
      flex-wrap: wrap;
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: $body;

      .material-icons {
        font-size: $xx-large;
        color: $text-secondary-color;
      }
    }
  }

  .profile-settings-rolewise {
    grid-template-columns: repeat(3, 1fr);
  }

  .logOut-button {
    border: 1px solid $border-color;
    border-radius: 8px;
    background-color: $main-bg-color;
    padding: 12px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.switch-business-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiList-root {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .MuiListItem-root {
    border: 1px solid $border-color;
    border-radius: 8px;
    color: $text-secondary-color;

    &.Mui-selected {
      background-color: initial;
      border-color: $primary-color;
      color: $primary-color;

      .material-icons {
        color: $primary-color;
      }
    }

    .material-icons {
      font-size: $title;
    }

    .MuiTypography-root {
      font-size: $title-small;
    }
  }

  .Mui-disabled {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .switch-business-drawer {
    padding: 20px;
  }
}

.profile-setting-fullScreen-wrapper {
  .panel-child-wrapper {
    height: calc(100% - 72px);
    position: relative;

    .account-overviewCard-view {
      flex: 1;
      width: 100%;
      position: relative;
      overflow: scroll;
      padding-top: 3px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .profile-grid-fields {
    display: flex;
    gap: 20px;

    .tab-container {
      flex: 1;
      .tab-panel {
        padding: 24px;
      }
    }

    .profile-grid {
      width: 20%;
      background-color: #ffffff;
      border-radius: 24px;
      box-shadow: 0 0 4px #00000030;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      overflow: auto;

      .profile-close-icon {
        position: absolute;
        z-index: 9;
        top: 40px;
        color: $text-secondary-color;
        left: 37px;
      }

      .large-title {
        font-size: $large;
        text-align: center;
      }
      @media (max-width: 1024px) {
        .large-title {
          font-size: $title;
        }
      }

      .profile-picture {
        width: 130px;
        height: 130px;
        font-size: 45px;
        background-color: #bdbdbd;
      }

      .profile-picture-wrapper {
        position: relative;
        height: 130px;
        width: 130px;
        font-size: 45px;
        background-color: #bdbdbd;
        border-radius: 100%;
        display: flex;
        flex: 0 0 130px;
        justify-content: center;
        overflow: hidden;

        > img {
          height: 100%;
        }
      }

      .img__overlay {
        align-items: baseline;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        transition: opacity 0.25s;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3490196078);
        width: 100%;
        gap: 7px;
        padding: 9px;
        height: 46px;

        .material-icons {
          cursor: pointer;
          background: #ffffffbd;
          border-radius: 50%;
          padding: 2px;
          font-size: 20px;
        }
      }

      .profile-details {
        background-color: $main-bg-color;
        color: $text-secondary-color;
        border-radius: 24px;
        font-size: $subTitle;
        box-shadow: 0 0 4px #00000030;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;
        height: 100%;
        word-break: break-all;

        .material-icons {
          font-size: 20px;
        }
      }

      .button.MuiButton-root {
        width: 100%;
        font-size: $subTitle;

        &.reset-button {
          color: $error-color;
          background-color: $light-error-color;
          box-shadow: none;
          border: none;
        }
      }

      .global-profile-edit-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        height: 100%;
        overflow: scroll;

        &.global-profile-edit-fields::-webkit-scrollbar {
          height: 0 !important;
          width: 0 !important;
        }
      }

      .edit-buttons {
        display: flex;
        width: 100%;
        gap: 10px;
      }
    }
    @media (max-width: 1024px) {
      .profile-grid {
        width: 25%;
        padding: 18px;

        .profile-details {
          padding: 14px;
          gap: 10px;
        }
        .button.MuiButton-root {
          padding: 4px 8px;
        }
      }
    }

    .account-overview-card-wrapper {
      width: 100%;
      display: grid;
      grid-gap: 15px;
      grid-auto-flow: row;
      grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
      height: 100%;

      //@media (max-width: 1050px) {
      //  grid-template-columns: 1fr 1fr;
      //}
      //@media (max-width: 755px) {
      //  grid-template-columns: 1fr;
      //}
      //height: 355px;

      .account-overview-card {
        height: 350px;
        max-width: 330px;
        min-width: 250px;
        border-radius: 24px;
        background-color: white;
        padding: 0 0 6px 0;
        box-shadow: 0 0 6px #00000029;

        &.accept {
          background-color: $primary-color;
        }

        &.warning {
          background-color: $secondary-color;
        }

        &.upgrade {
          background-color: $error-color;
        }

        .card-details {
          border-radius: 22px;
          background-color: white;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 16px 24px 16px 24px;
          gap: 22px;
          justify-content: space-between;

          .card-title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            .card-title {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              font-size: $title-small;
              min-width: 60%;

              .circle {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                box-shadow: 0 2px 5px #00000029;
              }

              .business-name,
              .current-selected-business {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
              }
              .current-selected-business {
                color: $primary-color;
              }
              .circle {
                background-color: $primary-color;
              }
            }

            .card-title-button {
              display: flex;
              gap: 10px;

              .ezy-menu,
              .switch-business-button {
                .MuiButtonBase-root,
                &.MuiButtonBase-root {
                  padding: 6px;
                  border-radius: 6px;
                  background-color: $main-bg-color;
                  box-shadow: none;

                  > span {
                    color: $border-active-color;
                    font-size: 20px;
                  }
                }
              }
            }
          }

          .MuiDivider-root {
            background-color: $border-color;
            height: 1px;
            margin-top: 16px;
          }

          .card-info {
            border-radius: 8px;
            border: 1px solid $border-color;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 21px;

            > div {
              color: $border-active-color;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 12px;

              span:first-child {
                font-size: 20px;
              }

              > span {
                font-size: $subTitle;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .chip-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .MuiChip-root {
              height: 44px;
              border-radius: 8px;

              > span {
                font-size: $subTitle;
              }
            }
          }

          .request-card-info {
            min-height: 138px;
            font-size: $subTitle;
            color: $border-active-color;
          }

          .card-button-wrapper,
          .card-dual-button-wrapper {
            display: flex;
            gap: 12px;

            .MuiButtonBase-root {
              width: 100%;
              box-shadow: none;
            }
          }

          .card-button-wrapper {
            .MuiButtonBase-root {
              width: 100%;
            }
          }

          .card-dual-button-wrapper {
            .MuiButtonBase-root {
              width: 50%;
            }
          }

          .create-new-business {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: $subTitle;
            color: $border-active-color;

            > div {
              height: 100%;
              display: flex;
              align-items: center;
            }

            .MuiButtonBase-root {
              padding: 8px 30px;
            }
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .account-overview-card-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(250px, 270px));

        .account-overview-card {
          height: 310px;
          .card-details {
            gap: 18px;
          }
        }
      }
    }
  }
}

.account-overview-general-drawer {
  padding: 24px;
}

.delete-account-warning-modal {
  .MuiDialog-paper {
    width: 480px;
  }
}

.account-overview-navbar {
  display: flex;
  gap: 12px;
}
