.integration-root-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .integration-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    grid-gap: 12px;
    height: 100%;
    position: relative;
    padding: 5px;
    overflow: auto;

    .integration-info {
      box-shadow: 0px 0px 6px #00000029;
      padding: 24px;
      border-radius: 12px;
      background-color: #ffffff;
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 10px;

      > img {
        width: 60px;
      }
    }
  }
  @media (max-width: 1024px) {
    .integration-grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.app-integration-settings-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;

  .app-integration-sidebar {
    position: relative;
    width: 25%;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0 0 4px #00000030;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    > img {
      width: 80px;
    }
    .no-pad-button {
      width: 100%;
    }
    .app-integration-location-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .app-integration-location-details {
        display: flex;
        flex-direction: column;
        border: 1px solid $main-bg-color;
        border-radius: 8px;
        padding: 10px;
        justify-content: space-between;
        cursor: pointer;

        &.selected-details-field {
          border: 1px solid $border-active-color;
          background-color: $main-bg-color;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .app-integration-sidebar {
      padding: 20px;
      gap: 8px;
    }
  }
}

.configuration-root-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 24px;

  .switch-container {
    align-items: flex-start;
    .ezySwitch.MuiSwitch-root {
      margin-top: 6px;
    }
  }

  .app-configuration-list {
    margin-top: 24px;
    padding-right: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    overflow: auto;
    height: 100%;
    position: relative;

    .app-configuration-details {
      display: flex;
      flex-direction: row;
      min-height: 70px;
      border: 1px solid $main-bg-color;
      border-radius: 8px;
      padding: 10px;
      justify-content: space-between;
      align-items: center;

      .no-pad-button.button.MuiButton-root {
        padding: 2px 12px;
        margin: 10px 0;
        border-radius: 6px;
      }
      .configuration {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        > img {
          width: 40px;
        }
      }
    }
  }
}

.app-info-root-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  gap: 12px;

  .app-info-container {
    flex: 1;
    border-radius: 8px;
    border: 1px solid $border-color;
    padding: 24px;
  }

  .app-info-sidebar {
    width: 25%;
    border-radius: 8px;
    border: 1px solid $border-color;
    padding: 24px;
  }
}

.configuration-action-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .app-configuration-details {
    display: flex;
    flex-direction: row;
    min-height: 70px;
    border: 1px solid $main-bg-color;
    border-radius: 8px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;

    .configuration {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      > img {
        width: 40px;
      }
    }
  }
  .configuration-info {
    background-color: $main-bg-color;
    border-radius: 8px;
    padding: 12px;
    font-size: $body;
  }
}
@media (max-width: 1024px) {
  .configuration-action-drawer {
    padding: 20px;
  }
}

.myob-login-modal {
  .login-dialog-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .button.MuiButton-root {
      width: 100%;
    }
  }
}
