$primary-color: #34a853;
$primary-hover-color: #24753a;
$secondary-color: #fbbc05;
$secondary-hover-color: #af8303;
$text-main-color: #000;
$text-secondary-color: #7f7f7f;
$placeholder-color: #00000080;
$hover-color: #0000000d;
$selected-color: #34a85314;
$border-color: #dfdfdf;
$border-active-color: #7f7f7f;
$disable-color: #e5e5e5;
$main-bg-color: #f2f5f8;
$error-color: #ff6565;
$light-error-color: #ffecec;
$light-decline-color: #fdeded;
$light-success-color: #f1fff4;
$light-secondary-color: #fff9e9;
$blue-color: #0099ff;
$dark-blue-color: #001ff1;
