// newsFeed drawer
.add-newsfeed-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input {
    padding: 10px 20px;
  }

  .media-icon-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
  }

  .uploader {
    padding: 24px;
    border-radius: 8px;
  }

  .MuiList-root {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .MuiListItem-root {
    border: 1px solid $border-color;
    border-radius: 8px;

    .MuiListItemButton-root {
      padding: 8px 16px;
    }

    .MuiListItemText-primary {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media (max-width: 1024px) {
  .add-newsfeed-drawer {
    padding: 20px;
  }
}

.newsFeed-confirmation-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .confirmed-text {
    .MuiListItemText-secondary {
      color: $primary-color;
    }
  }

  .unconfirmed-text {
    .MuiListItemText-secondary {
      color: $error-color;
    }
  }

  .iconButton.MuiButton-root {
    padding: 6px;
  }
}

.post-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 6px #00000029;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.important-post {
    border: 1px solid $primary-color;
  }

  .location-person-string {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }

  hr {
    margin: 0;
  }

  .post-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .no-pad-button.button.MuiButton-root {
      padding: 2px 12px;
    }
  }

  .post-media-container {
    width: 100%;
    overflow: hidden;

    &.post-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      > img,
      video,
      .pdf-container {
        height: 200px;
      }
    }

    > img,
    video {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    img:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .material-icons {
      font-size: 3.25rem;
    }

    .pdf-container {
      background-color: $text-secondary-color;
      width: 100%;
      height: 300px;
      color: white;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  .iconButton.MuiButton-root {
    padding: 8px;
    border-radius: 6px;
    .material-icons {
      font-size: $title-small;
    }
  }

  .post-comment-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .comment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $main-bg-color;
      border-radius: 8px;
      padding: 2px 12px;
    }
  }
}

.see-more-text {
  color: $primary-color;
  cursor: pointer;
}
.see-more-text:hover {
  text-decoration: underline;
}

.separator-circle {
  width: 5px;
  height: 5px;
  background-color: $border-active-color;
  border-radius: 50%;
}
