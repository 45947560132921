//dashboard cards
.dashboard-card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  grid-gap: 12px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(calc((100% - 24px) / 3), calc((100% - 24px) / 3)), 1fr)
  );

  .divide-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .dashboard-card {
      min-height: 150px;
      height: max-content;
      max-height: 50%;
    }

    .dashboard-card:last-child {
      flex: 1;
      height: 100%;
      max-height: 100%;
    }
  }

  .dashboard-card {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    overflow: hidden;

    .card-header {
      margin: 0 10%;
      background-color: $main-bg-color;
      padding: 12px;
      text-align: center;
      border-radius: 0 0 16px 16px;
    }
    @media (max-width: 1024px) {
      .card-header {
        padding: 6px;
        font-size: $subTitle;
      }
    }

    .card-body {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .card-info {
        padding: 12px 24px;
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-size: $subTitle;
        }

        .description {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: $text-secondary-color;
          font-size: $body;
          width: 100%;

          .material-icons {
            font-size: 20px;
          }

          span {
            word-break: break-all;
          }
        }
      }
      @media (max-width: 1024px) {
        .card-info {
          padding: 8px 12px;

          .title {
            font-size: $body;
          }

          .description {
            font-size: $caption;
            .material-icons {
              font-size: $subTitle;
            }
          }
        }
      }

      @media (max-width: 820px) {
        .card-info {
          padding: 6px 8px;
        }
      }

      .card-info.card-hover:hover {
        cursor: pointer;
        background-color: $main-bg-color;
      }

      .card-info:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}

//calender-tab
.calender-panel-wrapper {
  height: 100%;
  overflow: unset;

  .calender-panel-header {
    padding: 0 24px 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $title-small;
    > div {
      justify-content: flex-end;
    }
  }
  @media (max-width: 1024px) {
    .calender-panel-header {
      font-size: $subTitle;
    }
  }

  .calender-grid {
    display: grid;
    grid-template-rows: 49px auto 40px 64px;
    grid-row: 4;
    height: calc(100% - 34px);

    .calender-header-field {
      background-color: $main-bg-color;
      color: $text-secondary-color;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      text-align: center;
      height: 49px;

      > div {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .current-date {
        color: initial;
        border-bottom: 1px solid black;
      }
    }
    @media (max-width: 1024px) {
      .calender-header-field {
        height: 40px;
        > div {
          font-size: $body;
        }
      }
    }

    .cards-field {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      overflow: hidden;

      > div {
        overflow: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }

      .schedule-card {
        display: flex;
        flex-direction: column;
        margin: 8px;
        padding: 12px;
        border: 1px solid $border-color;
        border-radius: 8px;
        padding-top: 0;
        height: fit-content;

        .schedule-card-title {
          padding: 8px;
          border-radius: 0 0 8px 8px;
          background-color: $main-bg-color;
          justify-content: space-around;
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          gap: 5px;
          margin-bottom: 12px;
          .material-icons {
            font-size: $subTitle;
          }

          .iconButton.MuiButton-root {
            padding: 4px;
            border-radius: 4px;
          }

          &.Scheduled,
          &.Approved {
            color: $primary-color;
            background-color: $light-success-color;
          }

          &.Pending,
          &.Awaiting {
            color: $secondary-color;
            background-color: $light-secondary-color;
          }
        }
        @media (max-width: 1024px) {
          .schedule-card-title {
            font-size: $body;
            align-items: flex-end;

            .iconButton.MuiButton-root {
              padding: 2px;
            }
          }
        }

        .schedule-card-details {
          display: flex;
          color: $text-secondary-color;
          flex-direction: column;
          font-size: $body;

          .detail-field {
            display: flex;
            align-items: center;
            gap: 8px;

            .detail {
              white-space: nowrap;
              flex: 1;
              text-overflow: ellipsis;
              overflow: hidden;
              min-width: 0;
            }
          }

          .material-icons {
            font-size: 20px;
          }
        }

        .card-action {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          hr {
            width: 100%;
          }

          .no-pad-button.button.MuiButton-root {
            padding: 2px 4px;
            font-size: $body;
            width: 100%;
            border-radius: 4px;
          }

          .card-action-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
          }
        }
      }

      @media (max-width: 1024px) {
        .schedule-card {
          margin: 6px 3px;
          padding: 6px;
          padding-top: 0;

          .schedule-card-details {
            font-size: $caption;

            .detail-field {
              gap: 4px;
            }
            .material-icons {
              font-size: $subTitle;
            }
          }
        }
      }
    }

    .other-schedule-title {
      background-color: $main-bg-color;
      color: $primary-color;
      text-align: center;
      padding: 8px;
    }

    .other-schedule-field {
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      > div {
        border-right: 1px solid $border-color;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > div:last-child {
        border: none;
      }
    }
  }
  @media (max-width: 1024px) {
    .calender-grid {
      grid-template-rows: 40px auto 40px 64px;
    }
  }
}

.shift-details-accordion {
  padding: 0 24px;

  .shift-details-drawer-field {
    display: flex;
    flex-direction: column;
    gap: 6px;
    background: white;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    color: $text-secondary-color;
    font-size: $body;

    .material-icons {
      font-size: 20px;
    }
  }
}
@media (max-width: 1024px) {
  .shift-details-accordion {
    padding: 0 20px;
  }
}

.swap-requests-drawer {
  .MuiToggleButton-root {
    padding: 5px;
    width: 100%;
  }

  .swap-requests-button {
    &.button.MuiButton-root {
      padding: 2px 4px;
      width: 100%;
      border-radius: 4px;
    }
  }
  .people-invite-list-view > span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
