.task-root-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  position: relative;

  .task-list-sidebar {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    .location-by-area {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      min-height: 200px;
      max-height: 800px;

      .task-date-picker {
        .MuiFormControl-root input {
          min-width: unset;
          padding: 12px;
        }
      }
      .location-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
      }
    }

    .task-filter-container {
      background-color: #ffffff;
      box-shadow: 0 0 6px #00000029;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .filters {
        cursor: pointer;
        padding: 12px 8px;
        display: flex;
        gap: 16px;
      }
      @media (max-width: 1024px) {
        .filters {
          padding: 8px 6px;
          gap: 8px;
          font-size: $body;
          .material-icons {
            font-size: 20px;
          }
          .complete-task {
            text-align: right;
          }
        }
      }

      .selected-filter {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    @media (max-width: 1024px) {
      .task-filter-container {
        font-size: $body;
      }
    }

    .task-side-card-wrapper {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 0px 6px #00000029;
      position: relative;
      min-height: 100px;
      max-height: 600px;

      .area-check-list {
        padding: 10px !important;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
      }

      .title-text {
        border-bottom: 1px solid $border-color;
        padding: 10px;
      }
      .MuiList-root {
        overflow: auto;
      }
    }
  }
  @media (max-width: 1024px) {
    .task-list-sidebar {
      width: 25%;
    }
  }

  .task-list-wrapper {
    width: 100%;
    padding: 0 8%;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1;

    .task-list-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      .menu-button {
        margin-left: auto;
        .button.MuiButton-root {
          padding: 8px 16px;
          font-size: $subTitle;
        }
      }
      @media (max-width: 1024px) {
        .menu-button {
          .add-task-menu {
            .button.MuiButton-root {
              padding: 6px 14px;
              font-size: $subTitle;
            }
          }
        }
      }

      .MuiButton-endIcon {
        .material-icons:nth-of-type(1) {
          display: none;
        }
      }
    }

    .task-grid {
      display: flex;
      flex-direction: column;
      height: calc(100% - 66px);
      gap: 20px;
      overflow: auto;
      padding: 0 3px;
      margin-top: 20px;
      position: relative;

      .no-data-found-container {
        margin-top: 0;
      }
    }
  }
}

.add-task-menu {
  .MuiPaper-root {
    width: auto !important;
  }
}
