.add-people-root-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .people-field-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .add-people-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 5px 24px;
    background-color: $main-bg-color;
    font-size: $title-small;
  }
}
@media (max-width: 1024px) {
  .add-people-root-container {
    gap: 16px;
  }
}

.edit-people-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .people-profile-container {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px 24px;
    grid-auto-flow: row;
    grid-template-areas:
      'profile . . .'
      'profile . . .'
      'profile . . .'
      'profile . . .';
  }

  .people-profile {
    padding: 18px 32px;

    .profile-picture-wrapper {
      overflow: hidden;

      > img {
        height: 100%;
      }
    }

    .img__overlay {
      align-items: baseline;
      bottom: 0;
      display: flex;
      justify-content: center;
      position: absolute;
      transition: opacity 0.25s;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3490196078);
      width: 100%;
      gap: 7px;
      padding: 9px;
      height: 46px;

      .material-icons {
        cursor: pointer;
        color: black;
        background: #ffffffbd;
        border-radius: 50%;
        padding: 2px;
        font-size: 20px;
      }
    }
    .shift-info {
      .MuiChip-root {
        height: 40px;
        font-size: $subTitle;
      }
    }
  }

  .people-employee-container {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr 1fr;
    gap: 12px 24px;
    grid-auto-flow: row;
    grid-template-areas:
      '. .'
      '. .'
      '. .'
      'workPeriod workPeriod';
  }

  .work-period {
    grid-area: workPeriod;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media (max-width: 1024px) {
  .edit-people-wrapper {
    gap: 16px;
  }
}

.profile-picture-dialog {
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    z-index: 99;
    color: white;
  }

  .MuiDialog-paper {
    min-width: unset !important;
    min-height: unset !important;
    max-width: 70%;
    max-height: 70%;
    height: auto;
    width: auto;

    > img {
      max-height: 650px;
    }
  }
}

.hourly-drawer,
.work-period-drawer,
.leave-request-drawer,
.leave-entitlement-drawer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;

  .MuiInputBase-input {
    padding: 10px 20px;
  }
}

.select-pay-rate {
  padding: 0 24px 24px 24px;
}

.library-field {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 12px;

  .inputField.MuiFormControl-root .MuiInputLabel-root {
    text-wrap: wrap;
  }
}

.hourly-field-grid {
  display: grid;
  padding: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  grid-auto-flow: row;
  grid-template-areas:
    'areaIds areaIds'
    '. .'
    '. .';
}

.areaIds {
  grid-area: areaIds;
}

.work-period-field-grid {
  display: grid;
  padding: 0 24px;
  grid-template-columns: 1fr;
  gap: 12px;
  grid-auto-flow: row;
}

.leave-request-field-grid {
  display: grid;
  padding: 0 24px;
  grid-template-columns: 1fr;
  gap: 12px;
  grid-auto-flow: row;

  .date-picker {
    width: 100%;
  }

  .leave-drawer-note {
    background-color: $main-bg-color;
    border-radius: 8px;
    padding: 16px;
    font-size: $body;
  }

  .leave-details-accordion-field {
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 75px;
    background: white;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;

    > & span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }

    > span:last-child {
      color: gray;
      font-size: $body;
    }
  }
}

.leave-entitlement-field-grid {
  display: grid;
  padding: 0 24px;
  grid-template-columns: 3fr auto 1fr;
  gap: 12px;
  grid-auto-flow: row;
  align-items: self-start;

  .add-balance-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    > span {
      padding-top: 25px;
    }
  }
}

.leave-detail-root-container,
.shift-detail-root-container {
  height: 100%;
  padding-bottom: 0;

  .title-with-primary-bg {
    padding: 5px 24px;
  }

  .MuiButton-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiToggleButton-root {
    padding: 5px;
    width: 250px;
  }

  .table-wrapper {
    width: 100%;
    height: calc(100% - 28px);
    overflow-x: auto;
  }
}

.shift-replace-drawer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .shift-replace-field-grid {
    display: grid;
    padding: 0 24px;
    grid-template-columns: 1fr;
    gap: 12px;
    grid-auto-flow: row;

    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: 10px;

      //
    }

    .MuiListItem-root {
      border: 1px solid $border-color;
      border-radius: 8px;

      .MuiListItemButton-root {
        padding: 8px 16px;
      }
    }

    .Mui-disabled {
      opacity: 0.8;
    }

    .people-invite-list-view > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .search-box {
      max-width: 100%;
    }
  }
}
@media (max-width: 1024px) {
  .shift-replace-drawer {
    padding: 20px 0;
    gap: 18px;

    .shift-replace-field-grid {
      padding: 0 20px;
    }
  }
}

.view-request-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .view-leave-field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    line-height: 24px;

    .name-date-field {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1px;
      color: $border-active-color;
    }

    > span:first-child {
      font-size: $subTitle;
      color: $text-main-color;
    }

    > span {
      color: $border-active-color;
      align-items: center;
    }
  }
}

.people-details-email-resend {
  &.MuiLink-root {
    text-decoration: none;
    color: $secondary-color;

    &:hover {
      color: $secondary-hover-color;
      cursor: pointer;
    }
  }
}

.people-reset-login-modal {
  align-items: center;

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .reset-login-title-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;

    .reset-login-main-title {
      font-size: $xx-large;
      color: $primary-color;
    }

    .reset-login-title-info {
      color: $text-main-color;
    }
  }
}

.import-csv-error-icon {
  color: $error-color;
}

.edit-shift-drawer-wrapper {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .date-picker {
    width: 100%;
  }

  .edit-shift-drawer-time-header {
    border-radius: 8px;
    background-color: $main-bg-color;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px;

    .edit-shift-drawer-time {
      font-size: $x-large;
    }

    .edit-shift-drawer-sub-label {
      font-size: $title-small;
      color: $border-active-color;
    }
  }

  .edit-shift-drawer-time-selection {
    display: flex;
    gap: 12px;
    padding: 0 24px;
  }
  .edit-shift-drawer-note {
    padding: 0 24px;
  }
  .shift-warning {
    margin: 0 24px;
  }
}

.edit-people-profile-schedule-info,
.edit-people-profile-schedule-status {
  text-align: center;
  color: $text-main-color;
}

.edit-people-profile-schedule-status {
  color: $border-active-color;
  font-size: $caption;
}

.edit-people-area-checks-lists {
  &.MuiList-root {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;

    .MuiListItem-root {
      .MuiListItemButton-root {
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        .MuiListItemIcon-root {
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
            align-items: center;
            > span {
              .watch-later-icon {
                font-size: 21px;
              }
            }
          }
        }
        > div {
          width: 100%;

          .break-type,
          .payout {
            color: $text-main-color;
            font-size: $body;
          }
        }
      }
    }
  }
}
.edit-people-area-list-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  .MuiList-root {
    padding: 0;
    gap: 12px;
    display: flex;
    flex-direction: column;
    .MuiListItem-root {
      border: 1px solid $border-color;
      border-radius: 8px;
    }
  }
}

.add-journal-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 1024px) {
  .add-journal-drawer {
    padding: 20px;
  }
}

.journalList-root-container {
  padding: 0 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .material-icons {
    font-size: 20px;
    cursor: pointer;
  }

  .journal-details {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 10px;

    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .visibility-off {
    visibility: hidden;
  }
}

.add-unavailability-drawer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .date-picker {
    width: 100%;
  }
}

.unavailability-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 10px;
  position: relative;

  position: relative;
  .material-icons {
    font-size: 20px;
    cursor: pointer;
  }
  .unavailability {
    background-color: #ffffff;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row;
  }
}
