.MuiDrawer-paper {
  width: 670px;
  max-width: 670px;
  display: flex;
  flex-direction: column;
  border-radius: 20px 0 0 0;

  .drawer-header {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $title-small;
    background-color: $primary-color;
    padding: 24px;
    color: white;
  }

  .drawer-body {
    flex: 1;
    overflow: auto;

    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .MuiListItemIcon-root > .MuiCheckbox-root {
      display: flex;
      align-items: center;
      min-width: unset;
      padding: unset;
      margin-left: unset;
    }

    .MuiListItem-root {
      border: 1px solid $border-color !important;
      border-radius: 8px !important;

      &.Mui-selected {
        background-color: initial !important;
        border-color: $primary-color !important;
      }
    }
  }

  .drawer-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px;
    flex-wrap: wrap;

    .left-action {
      display: flex;
      flex: 1;
      justify-content: flex-start;
    }
  }
}
@media (max-width: 1024px) {
  .MuiDrawer-paper {
    width: 500px;

    .drawer-footer {
      .button.MuiButton-root {
        padding: 8px 28px;
      }
    }
  }
}
