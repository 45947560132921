.ezy-accordion-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .MuiAccordion-root {
    border-radius: 14px;
    //accordion-summary when not expand
    .ezy-accordion-summary {
      min-height: 40px;

      .MuiAccordionSummary-expandIconWrapper {
        color: black;
      }

      .MuiAccordionSummary-content {
        margin: 10px 0;
      }
    }
  }

  //when accordion is expand
  .MuiAccordion-root {
    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionSummary-root {
    & .Mui-expanded {
      margin: 10px 0;
      max-height: fit-content;
    }

    .ezy-accordion-summary-end-line {
      height: 2px;
      width: 100%;
      background-color: white;
    }
  }
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 0;
}

.accordion-root {
  &.MuiPaper-root {
    border-radius: 8px;
    background-color: $main-bg-color;
    box-shadow: none;

    &:before {
      background-color: white;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 0.6rem;
    }
  }
}

.ezy-accordion-details {
  position: relative;

  &.MuiAccordionDetails-root {
    padding: 0;
  }

  .ezy-divider-line {
    height: 2px;
    width: 100%;
    background-color: white;
  }

  .ezy-accordion-details-contain {
    padding: 10px 16px;
  }
}
