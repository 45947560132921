.add-timesheet-drawer,
.timesheet-history-drawer,
.view-timesheet-drawer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-size: $title-small;
    padding: 0 24px;
  }

  .MuiInputBase-input {
    padding: 10px 20px;
  }
  .title-wrapper {
    display: flex;
    > div {
      width: 50%;
    }
    .photo-heading {
      padding: 0;
    }
  }
  .timesheet-wrapper {
    display: flex;
    width: 100%;
    > div {
      width: 100%;
    }
    > img {
      width: 30%;
      margin-right: 24px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1024px) {
  .add-timesheet-drawer,
  .timesheet-history-drawer,
  .view-timesheet-drawer {
    padding: 20px 0;
    .title {
      padding: 0 20px;
    }
  }
}

.view-timesheet-data-wrapper,
.schedule-details-wrapper {
  margin: 0 24px;
  padding: 24px;
  background-color: $main-bg-color;
  border-radius: 8px;
  font-size: $body;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .data-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .title {
      text-align: left;
      font-size: $subTitle;
      white-space: nowrap;
      padding: 0;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $border-active-color;
      word-break: break-word;
    }
  }
}

.schedule-details-wrapper {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.add-timesheet-fields-grid {
  display: grid;
  padding: 0 24px;
  grid-template-columns: 1fr;
  gap: 12px;
  grid-auto-flow: row;
}
@media (max-width: 1024px) {
  .view-timesheet-data-wrapper,
  .schedule-details-wrapper {
    margin: 0 20px;
    padding: 20px;
  }
  .add-timesheet-fields-grid {
    padding: 0 20px;
  }
}

//timesheet history
.duration-menu-wrapper {
  .MuiMenu-list {
    padding: 0;
  }
  .date-duration-wrapper {
    display: flex;

    > div:first-child {
      border-right: 1px solid $text-secondary-color;
    }
    > div:last-child {
      background-color: $main-bg-color;
    }
    .options-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      min-width: 150px;
      max-width: 1200px;

      > span {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border: 1px solid $main-bg-color;
        border-radius: 8px;

        &.selected-option {
          border: 1px solid $text-secondary-color;
          border-radius: 8px;
          background-color: $main-bg-color;
        }
      }
    }
  }
}

.selectable-table {
  position: relative;
  &.MuiTableContainer-root .MuiTableBody-root tr {
    background-color: white;

    &.Mui-selected {
      background-color: $light-success-color;
      &:hover {
        background-color: $light-success-color;
      }
    }

    &:hover {
      background-color: white;
    }
    td {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 5px 10px;

      .MuiChip-root {
        height: 25px;
      }
    }
  }
}

.edit-timesheet-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 0 0 0;

  .view-timesheet-data-wrapper {
    margin-bottom: 24px;
    flex: 1;
    height: min-content;
  }

  .info-div {
    height: min-content;
    flex: 1;
    margin: 10px 0 0 24px;
    padding: 8px;

    &.error {
      margin: 10px 24px 0 24px;
    }
  }

  .view-timesheet-details {
    flex: 1;
    overflow: auto;

    .view-timesheet-data-wrapper {
      margin-bottom: 10px;
    }
    .no-pad-button {
      padding: 2px 20px;
    }

    .details-fields {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 10px;
      padding: 10px 24px;

      > div {
        .button.MuiButton-root {
          height: 42px;
          padding: 20px;
          align-self: flex-end;
        }
        .iconButton.MuiButton-root {
          align-self: flex-end;
          height: 42px;
        }
      }

      .hour-field {
        display: flex;

        > div:last-child {
          width: 60px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
        gap: 10px;
      }
    }

    .info-div {
      margin: 10px 24px;
    }
  }

  .details {
    flex: 1;
    overflow: auto;
    border-right: 3px solid $main-bg-color;
    .primary-bg {
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      > div:first-child {
        display: grid;
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .details-fields {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      padding: 10px 24px;

      > div {
        .button.MuiButton-root {
          height: 42px;
          padding: 20px;
          align-self: flex-end;
        }
        .iconButton.MuiButton-root {
          align-self: flex-end;
          height: 42px;
        }
      }
    }

    .break-details-field {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 24px;

      .break-details-with-time {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }
    }

    @media (max-width: 1024px) {
      .details-fields,
      .break-details-field {
        padding: 10px;
      }
    }
  }

  .actions {
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    overflow: auto;

    .button.MuiButton-root {
      padding: 5px 48px;
    }

    img {
      width: 220px;
      height: 260px;
      border-radius: 8px;
    }
  }
}

.timesheet-detail-wrapper .default-tabs-view .timesheet-activity-wrapper {
  height: 100%;
  .created-text {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: $text-secondary-color;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .MuiTableContainer-root {
    position: relative;
    height: calc(100% - 46px);
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
