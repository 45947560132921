.newsFeed-root-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  position: relative;

  .newsFeed-list-sidebar {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow: hidden;
    height: 100%;
    overflow: hidden;

    .newsFeed-filter-container {
      background-color: #ffffff;
      box-shadow: 0 0 6px #00000029;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .filters {
        cursor: pointer;
        padding: 12px 8px;
        display: flex;
        gap: 16px;
        width: 100%;
        align-items: center;
      }
      @media (max-width: 1024px) {
        .filters {
          padding: 8px 4px;
          gap: 6px;
          .material-icons {
            font-size: 20px;
          }
        }
      }

      .selected-filter {
        background-color: $primary-color;
        color: #ffffff;
      }
    }

    .newsFeed-side-card-wrapper {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 0px 6px #00000029;
      position: relative;
      min-height: 200px;
      max-height: 800px;

      .title-text {
        border-bottom: 1px solid $border-color;
        padding: 10px;
      }

      .MuiList-root {
        overflow: auto;

        .MuiListItemText-root {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;

          .MuiTypography-root {
            span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .newsFeed-list-sidebar {
      width: 25%;

      .newsFeed-filter-container {
        font-size: $body;
      }
    }
  }

  .newsFeed-list-wrapper {
    width: 100%;
    padding: 0 12%;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1;

    .newsFeed-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }

    .news-post-grid {
      display: flex;
      flex-direction: column;
      height: calc(100% - 66px);
      gap: 20px;
      overflow: auto;
      padding: 0 3px;
      margin-top: 20px;
    }
  }
  @media (max-width: 1024px) {
    .newsFeed-list-wrapper {
      padding: 0 6%;
    }
  }
  @media (max-width: 755px) {
    .newsFeed-list-wrapper {
      padding: 0;
    }
  }
}

.no-data-found-container {
  margin-top: 20px;
  height: 150px;
  border: 1px solid $border-color;
  box-shadow: 0px 0px 6px #00000029;
  background-color: white;
  border-radius: 8px;
}
