.time-sheets-list-wrapper {
  display: flex;
  gap: 10px;
  height: 100%;

  .time-sheets-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .time-sheets-table-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border: 1px solid $main-bg-color;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 3px #00000029;

      .MuiTableContainer-root {
        height: unset;
        min-height: 80px;
        background-color: #ffffff;
      }
      .separator {
        height: 14px;
        cursor: row-resize;
        background-color: $main-bg-color;
        width: 100%;
        border: 1px solid $main-bg-color;
        box-shadow: 0px 0px 6px #00000029;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        /* Prevent the browser's built-in drag from interfering */
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .material-icons {
          color: $primary-color;
          font-size: 20px;
          padding: 0;
          margin: 0;
        }
      }
      .timesheet-detail-wrapper {
        background-color: #ffffff;
        flex: 1;
        overflow: hidden;

        .MuiTabs-root {
          .MuiTab-root {
            padding: 0 40px;
          }
        }

        .default-tabs-view {
          padding: 0;
          height: calc(100% - 48px);

          .journal-wrapper {
            padding: 10px 24px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .button.MuiButton-root {
              align-self: flex-end;
            }

            .no-journal-text {
              background-color: $main-bg-color;
              border-radius: 8px;
              padding: 10px;
            }
          }
        }
      }
    }
  }

  .time-sheets-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .week-picker {
      width: 100%;
    }

    > div:last-child {
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  .time-sheets-side-menu {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;

    .MuiPaper-root {
      flex: 1;
    }

    .time-sheets-location-menu {
      .MuiButton-root {
        padding: 6px 14px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        max-width: 500px;
        min-width: 100px;

        .button-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    @media (max-width: 1024px) {
      .time-sheets-location-menu .MuiButton-root {
        height: 40px;
      }
    }

    .timesheet-person-list-wrapper {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: 8px;
      box-shadow: 0px 0px 6px #00000029;
      position: relative;
      height: calc(100% - 100px);

      .area-check-list {
        height: 100%;
        overflow: auto;
      }

      .title-text {
        border-bottom: 1px solid $border-color;
        padding: 4px 12px;
      }

      .MuiListItem-root {
        &.Yellow {
          background-color: $light-secondary-color;

          &.Mui-selected {
            border: 2px solid $secondary-color;
          }
        }
        &.Green {
          background-color: $light-success-color;

          &.Mui-selected {
            border: 2px solid $primary-color;
          }
        }
      }

      .person-details {
        .name {
          font-size: $subTitle;
        }
        .details {
          font-size: $body;
          color: $text-secondary-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .time-sheets-side-menu {
      width: 25%;
    }
  }
}

.area-menu-item {
  .manu-list-value {
    padding-left: 30px;
    // color: $text-secondary-color;
  }
}

.location-menu-list {
  .manu-list-value {
    color: $primary-color;
  }
}
