.file-upload {
  .MuiDialog-paper {
    width: 680px;
    height: auto;
  }
}

.uploader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border: 1px dashed $border-color;
  padding: 80px;
  font-size: $body;
}

.input-file-type {
  display: none;
}
