.add-location-root-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .add-location-title-header {
    height: 42px;
    background-color: $main-bg-color;
    display: flex;
    align-items: center;
    padding: 5px 24px;
    justify-content: space-between;
    font-size: $title-small;

    > span {
      display: flex;
      gap: 5px;
      align-items: center;

      .no-pad-button {
        padding: 4px 16px;
      }
    }

    .add-area-header {
      display: flex;
      gap: 20px;
      align-items: center;
      > span {
        display: flex;
        gap: 5px;
      }
      .no-pad-button {
        padding: 4px 16px;
      }
    }
  }
  @media (max-width: 1024px) {
    .add-location-title-header {
      height: 40px;
    }
  }

  .location-sub-header-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  //.edit-general-location-text-area {
  //  .MuiInputBase-root {
  //    padding: 10px 20px;
  //  }
  //}
}

.copy-dialog {
  .MuiDialog-paper {
    width: 680px;
    height: auto;

    .location-select-row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
    }

    .duplicate-location-select-row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
    }
  }
}

.two-grid-working-hours {
  padding: 0 24px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));

  .working-hours-card {
    padding: 0 12px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 2px solid $border-color;
    border-radius: 8px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;

      .day-label {
        font-size: $title-small;
      }

      .MuiCheckbox-root {
        padding: 8px 0 8px 8px;
      }
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      .date-picker .MuiInputBase-root input,
      .week-picker .MuiInputBase-root input {
        padding: 10px !important;
      }
      @media (max-width: 1024px) {
        .date-picker .MuiInputBase-root input,
        .week-picker .MuiInputBase-root input {
          padding: 8px !important;
        }
      }

      .MuiInputBase-root {
        padding-right: 0;
      }

      .MuiInputAdornment-root {
        display: none;
      }
    }
  }

  .working-hours-card.border-active {
    border-color: $primary-color;

    .day-label {
      color: $primary-color;
    }
  }

  //.working-hours-card.disabled-card {
  //  opacity: 0.8;
  //}
}

.accordion-wrapper {
  width: 100%;
  height: 100%;

  &.ezy-accordion-wrapper {
    gap: 16px;
  }

  padding: 0 20px;

  .accordion-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;
  }

  .accordion-summary {
    font-size: $subTitle;
  }

  .accordion-details {
    .accordion-Details-switch-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .accordion-Details-switch-container {
        display: flex;
        gap: 10px;

        .accordion-Details-switch-label {
          font-size: $subTitle;
        }
      }

      .accordion-Details-switch-description {
        padding-left: 37px;
        font-size: $body;
        opacity: 0.6;
      }
    }
  }
}

.archive-dialog {
  .MuiDialog-paper {
    width: 650px;
    height: auto;

    .error-div {
      display: flex;
      flex-direction: row;
      background: $light-decline-color;
      gap: 10px;
      border-radius: 8px;
      padding: 12px;
      font-size: $body;

      > span {
        color: $error-color;
      }

      > div {
        font-size: $subTitle;

        > span {
          color: $error-color;
          cursor: pointer;
        }
      }
    }
  }
}

.people-import-table {
  .ezy-tooltip {
    display: inline;
  }
}
