@font-face {
  font-family: 'reemkufi';
  src: local('reemkufi'), url(assets/fonts/ReemKufi-Regular.ttf) format('truetype');
}

@import 'styles/colorPalette';
@import 'styles/typography';
@import 'styles/common-style';
@import 'styles/components';

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: reemkufi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  *::placeholder {
    font-size: $body;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  border-radius: 8px;
  outline: none;
}

// /* scrollbar starts here */
* {
  scrollbar-color: $border-active-color white !important;
  scrollbar-width: thin !important;
}

/* width */
::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $border-color !important;
  border-radius: 7px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $border-active-color !important;
}

// /* scrollbar ends here */

.MuiButtonBase-root.MuiMenuItem-root {
  font-size: $body;
}

input::placeholder {
  color: $placeholder-color !important;
  opacity: 1;
}

input:disabled {
  pointer-events: none !important;
}

.MuiTab-root {
  text-transform: none !important;
}

.MuiInputBase-root {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.invoice-pdf {
  display: none;
  position: relative;
  width: 100%;
  min-height: 100%;
}

#invoice-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;

  thead {
    th {
      padding: 5px;
      border-bottom: 1px dashed #7f7f7f;
      color: #7f7f7f;
      font-weight: 300;
    }
  }

  tbody {
    padding-top: 5px;
  }

  .invoice-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    min-height: 75px;
    background-color: #34a853;
    border-bottom: 5px solid #fbbc05;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 180px;
    }
  }

  .main {
    height: 100%;
    width: 100%;
    /* background-color: #f2f5f8; */
    display: flex;
    flex-direction: column;

    .details {
      width: 100%;
      background-color: #fff;
      display: flex;
      gap: 20%;

      .bill-details {
        width: 50%;
        padding: 20px 40px;

        table {
          width: 100%;
        }
      }
    }

    .total {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .total-wrapper {
        margin-right: 40px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 6px;

        .amount-wrapper:not(:last-child) {
          padding: 5px 0;
          border-bottom: 1px dashed #7f7f7f;
        }
      }
    }
  }

  .amounts {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .amount-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      table {
        width: calc(100% - 80px);
        background-color: #fff;
        border-collapse: collapse;
        border-radius: 6px;
        text-align: left;

        td {
          padding: 5px;
        }
      }
    }
  }

  .note {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    padding: 20px 0 10px 0;
    flex: 1;
    align-items: flex-end;

    .dot {
      width: 15px;
      height: 20px;
      background-color: #fbbc05;
    }

    .text {
      flex: 1;
    }
  }

  .head-with-dot {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    /* padding: 20px 0 10px 0; */
    flex: 1;
    align-items: flex-start;

    .dot {
      width: 15px;
      height: 20px;
      background-color: #fbbc05;
    }
  }

  .second-details-wrapper {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .head-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .heading {
    font-size: 18px;
    font-weight: 600;
  }

  .sub-heading {
    color: #7f7f7f;
  }

  .usage-details {
    display: flex;
    align-items: center;
    margin: 0 40px 0 40px;
    gap: 10px;
  }

  .usage-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .usage-wrapper:first-child {
    border-right: 1px dashed #7f7f7f;
  }

  .usage-light-head {
    color: #7f7f7f;
  }

  .second-details-wrapper:last-child {
    padding-bottom: 40px;
  }

  .users-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin: 0 40px;

    .user {
      display: flex;
      align-items: center;
      padding-left: 10px;
      height: 40px;
      background-color: #f2f5f8;
      border-radius: 8px;
    }
  }

  .devider {
    width: 100%;
    min-height: 20px;
    height: 20px;
    background-color: #f2f5f8;
  }
}
