.panel-auth-layout {
  position: relative;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100%;
  background: url(../../assets/images/Left_Background.png) left top no-repeat,
    url(../../assets/images/Right_Background.png) right bottom no-repeat $main-bg-color;
}

.panel-layout {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100%;
  background: $primary-color;
  display: flex;
  flex-direction: column;
}

.panel-layout.image-bg {
  background: url(../../assets/images/Left_Background.png) left top 80px no-repeat,
    url(../../assets/images/Right_Background.png) right bottom no-repeat $main-bg-color !important;
}

.panel-child-wrapper {
  width: 100%;
  height: 100%;
  background-color: $main-bg-color;
  overflow: auto;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px -2px 4px #00000030;

  > div {
    height: 100%;
  }

  //.panel-paper {
  //  padding: 24px;
  //  width: 100%;
  //  height: 100%;
  //  box-shadow: 0px 0px 6px #00000029;
  //  border-radius: 12px;
  //}
}
