.ezy-menu {
  .button.MuiButton-root {
    padding: 6px 20px;
    min-width: fit-content;
    font-size: $title-small;
  }

  .MuiButton-endIcon {
    justify-content: right;
    flex: 1;
  }

  .menu-icon {
    padding: 0px;
  }
}

.ezy-menu-list {
  .MuiList-root {
    width: 100%;
    max-height: 350px;

    .MuiButtonBase-root.MuiMenuItem-root {
      > div {
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;
        white-space: break-spaces;
        word-break: break-word;

        .material-icons {
          font-size: 18px;
        }
      }
    }
  }
}
