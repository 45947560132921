.auth-dialog {
  .MuiDialog-paper {
    width: 480px;
  }
}

.auth-wrapper {
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .info-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .auth-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .field-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .auth-redirect-text {
      font-size: $body;
    }
    .forgot-password {
      width: 100%;
      text-align: end;
    }
    .action-button {
      padding: 15px 0;
    }
    .tnc-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      .tnc-section {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.privacy-policy-wrapper,
.terms-and-condition-wrapper {
  .r-form-wrapper {
    padding: 20px 150px;
  }
  .r-form-wrapper .MuiPaper-root {
    height: max-content;
  }
  @media (max-width: 1024px) {
    .r-form-wrapper {
      padding: 20px 80px;
    }
  }
  .header-wrapper {
    text-align: center;
    padding-bottom: 50px;
  }
  .main-title-text {
    font-size: $x-large;
  }
  .subtitle {
    font-size: $title-small;
    color: $text-secondary-color;
  }

  .title {
    font-size: $title;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    text-align: justify;
    li {
      padding: 5px 0;
    }
  }
}
